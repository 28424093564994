import React, { useState } from "react";
import { Button, Col, notification, Row, Typography } from "antd";
import { motion } from "framer-motion";
import { useSettingsServiceRestoreDatabase } from "api/queries";
import save from "save-file";
import axios, { Axios } from "axios";
import { OpenAPI } from "api/requests";

const BackupComponent = () => {
  const [loading, setLoading] = useState(false);
  const { mutate: uploadFile } = useSettingsServiceRestoreDatabase();

  const handleBackup = async () => {
    try {
      setLoading(true);
      const response = await axios.post(OpenAPI.BASE + "/api/settings/backup", {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setLoading(false);

      save(response.data, "backup_latest.json");
    } catch (err) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Something went wrong",
      });
    }
  };

  const saveFile = async (blob: any) => {
    const a = document.createElement("a");
    a.download = "backup_latest.zip";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];

    uploadFile(
      {
        formData: {
          file,
        },
      },
      {
        onSuccess: (data) => {
          console.log(data);

          if (fileInputRef?.current?.files?.length) {
            // fileInputRef?.current?.files = [];
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  React.useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: "0px" }}>
            Backup & Restore Company Data
          </Typography.Title>
        </Col>

        <Col xs={12} style={{ padding: "1rem" }}>
          <Button loading={loading} onClick={handleBackup}>
            Download Backup
          </Button>
        </Col>

        <Col xs={12} style={{ padding: "1rem" }}>
          <Button
            onClick={() => {
              if (fileInputRef?.current) {
                fileInputRef?.current?.click();
              }
            }}
          >
            Restore Backup
          </Button>
        </Col>

        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={handleFileChange}
        />
      </Row>
    </motion.div>
  );
};

export default BackupComponent;
