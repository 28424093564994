import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import {
  useInventoryServiceGetBrands,
  useInventoryServiceGetBrandsKey,
} from "api/queries";
import { IBrandItem } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface IBrandSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IBrandItem[];
  selectProps?: Partial<SelectProps>;
}

const BrandSelectWithForm: React.FC<IBrandSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetBrands(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useInventoryServiceGetBrandsKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            brandName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a brand"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.brandName,
            value: _v.idBrand,
            key: _v.idBrand + "brand-id-select-brand2",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const BrandSelectNoForm: React.FC<IBrandSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetBrands(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useInventoryServiceGetBrandsKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            brandName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a brand"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.brandName,
            value: _v.idBrand,
            key: _v.idBrand + "brand-id-select-brand2",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

export default function BrandSelect(props: IBrandSelectProps) {
  if (props.isForm) {
    return <BrandSelectWithForm {...props} />;
  }
  return <BrandSelectNoForm {...props} />;
}
