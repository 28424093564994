import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, message, notification, Row, Table, Typography } from "antd";
import {
  useAccountingServiceDeleteExpenseCategory,
  useAccountingServiceGetExpenseCategories,
  useAccountingServiceGetExpenseCategoriesKey,
} from "api/queries";
import qs from "qs";
import { useEffect, useState } from "react";
import { CreateExpenseCategoryModal } from "./CreateExpenseCategoryModal";
import { useQueryClient } from "@tanstack/react-query";
import { TableActions } from "components/common";
import EditExpenseCategoryModal from "./EditExpenseCategoryModal";
import { motion } from "framer-motion";

export const ExpenseCategoryListingPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [filtersQuery, setFiltersQuery] = useState("");
  const [createExpenseCategoryModalOpen, setCreateExpenseCategoryModalOpen] =
    useState(false);
  const [updateExpenseCategoryModalOpen, setUpdateExpenseCategoryModalOpen] =
    useState(false);
  const [selectedExpenseCategory, setSelectedExpenseCategory] =
    useState<any>(null);

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetExpenseCategories(
      {
        filters: filtersQuery,
        pagination: qs.stringify({
          offset: (page - 1) * limit,
          limit,
        }),
        sort: sortQuery,
      },
      [useAccountingServiceGetExpenseCategoriesKey],
      { enabled: true, staleTime: 1000 * 60 * 100 }
    );

  const { mutate: deleteExpenseCategoryMutation, isPending: isDeletePending } =
    useAccountingServiceDeleteExpenseCategory({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [useAccountingServiceGetExpenseCategoriesKey],
          refetchType: "all",
        });
        message.success("Expense category deleted successfully");
      },
      onError: (error: any) => {
        notification.error({
          description: error?.body?.message || error?.response?.data?.message || error?.message,
          type: "error",
          message: "Failed to delete expense category",
        });
      },
    });

  const handleOpenUpdateExpenseCategoryModal = (expenseCategory: any) => {
    setSelectedExpenseCategory(expenseCategory);
    setUpdateExpenseCategoryModalOpen(true);
  };

  const handleCloseUpdateExpenseCategoryModal = () => {
    setSelectedExpenseCategory(null);
    setUpdateExpenseCategoryModalOpen(false);
  };

  const handleDeleteExpenseCategory = async (id: string) => {
    await deleteExpenseCategoryMutation({
      id,
    });
    refetch();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: "auto",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      width: "auto",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_i: any, row: any) => (
        <TableActions
          onEdit={() => handleOpenUpdateExpenseCategoryModal(row)}
          onDeletePopConfirm={() => handleDeleteExpenseCategory(row.idExpenseCategory.toString())}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This expense category will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  const handleCreateExpenseCategoryModalClose = (isSuccess?: boolean) => {
    setCreateExpenseCategoryModalOpen(false);
  };

  const handleCreateExpenseCategoryModalOpen = () => {
    setCreateExpenseCategoryModalOpen(true);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useAccountingServiceGetExpenseCategoriesKey],
    });
  }, [page, limit, sortQuery, filtersQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title>Expense Categories</Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateExpenseCategoryModalOpen}
          >
            Create new
          </Button>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isRefetching}
            key={"expense-category-list"}
            rowKey={(item) => item.idExpenseCategory}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
          />
        </Col>
        <CreateExpenseCategoryModal
          isOpen={createExpenseCategoryModalOpen}
          onClose={handleCreateExpenseCategoryModalClose}
          queryClient={queryClient}
        />
        <EditExpenseCategoryModal
          isOpen={updateExpenseCategoryModalOpen && !!selectedExpenseCategory}
          onClose={handleCloseUpdateExpenseCategoryModal}
          queryClient={queryClient}
          defaultValues={selectedExpenseCategory}
        />
      </Row>
    </motion.div>
  );
};
