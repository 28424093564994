import { useQueryClient } from "@tanstack/react-query";
import { Form, Input, Modal, Switch } from "antd";
import {
  useInventoryServiceCreateServiceCategory,
  useInventoryServiceGetServiceCategoryKey,
} from "api/queries";
import React from "react";

export const CreateServiceCategoryModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useInventoryServiceCreateServiceCategory(
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetServiceCategoryKey],
        });
        onClose();
      },
    }
  );

  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: {
        serviceCategoryName: values.serviceCategoryName,
        serviceCategoryDescription: values.serviceCategoryDescription,
        isActive: values.isActive,
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Service Category"
      onOk={() => form.submit()}
      okText="Create"
      cancelText="Cancel"
      okButtonProps={{ form: "createServiceCategoryForm", loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        id="createServiceCategoryForm"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="serviceCategoryName"
          label="Name"
          rules={[{ required: true, message: "Please input a name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="serviceCategoryDescription"
          label="Description"
          rules={[{ required: true, message: "Please input a description" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          name="isActive"
          label="Is Active"
          valuePropName="isActive"
          rules={[{ required: true }]}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
