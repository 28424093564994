import { QueryClient } from "@tanstack/react-query";
import { Alert, Button, Col, Form, Input, Modal, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useUsersServiceCreateRole,
  useUsersServiceGetRolesKey,
} from "api/queries";
import { useEffect } from "react";

interface ICreateRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}

const CreateRoleModal: React.FC<ICreateRoleModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useUsersServiceCreateRole({
    onError: (error: any) => {
      console.dir(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useUsersServiceGetRolesKey],
        refetchType: "all",
      });
      form.resetFields();
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Role"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="create-role-form"
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="roleName"
              label="Role Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="roleDescription"
              label="Description"
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateRoleModal;