import { Button, Col, message, notification, Row, Typography } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  useInventoryServiceDeleteModel,
  useInventoryServiceGetModels,
  useInventoryServiceGetModelsKey,
} from "api/queries";
import qs from "qs";
import Table, { ColumnType } from "antd/es/table";
import { TableActions } from "components/common";
import { useEffect, useState } from "react";
import CreateProductModelModal from "./CreateProductModal";
import EditProductModelModal from "./EditProductModal";

const ProductModelSettingsPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [createProductModelModalOpen, setCreateProductModelModalOpen] =
    useState(false);
  const [editProductModelModalOpen, setEditProductModelModalOpen] =
    useState(false);
  const [selectedProductModel, setSelectedProductModel] = useState<any>(null);

  const queryClient = useQueryClient();

  const { data, isLoading, error, isError, refetch } =
    useInventoryServiceGetModels({
      filters: "",
      pagination: qs.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      sort: sortQuery,
    });

  const { mutate: deleteProductModelMutation, isPending: isDeletePending } =
    useInventoryServiceDeleteModel({
      onSuccess: () => {
        message.success("Model deleted successfully");
        refetch();
      },
      onError: (error: any) => {
        notification.error({
          description: error?.body?.message || error?.response?.data?.message || error?.message,
          type: "error",
          message: "Failed to delete model",
        });
      },
    });

  const columns: ColumnType<any>[] = [
    {
      title: "Model Name",
      dataIndex: "modelName",
      key: "modelName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Model Description",
      dataIndex: "modelDescription",
      key: "modelDescription",
      width: "auto",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_text, record) => (
        <TableActions
          onEdit={() => handleOpenEditProductModelModal(record)}
          onDeletePopConfirm={() => handleDeleteProductModel(record.idModel)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This model will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  const handleDeleteProductModel = async (id: string) => {
    await deleteProductModelMutation({
      idModel: +id,
    });
    refetch();
  };

  const handleOpenCreateProductModelModal = () => {
    setCreateProductModelModalOpen(true);
  };

  const handleCloseCreateProductModelModal = () => {
    setCreateProductModelModalOpen(false);
  };

  const handleOpenEditProductModelModal = (model: any) => {
    setEditProductModelModalOpen(true);
    setSelectedProductModel(model);
  };

  const handleCloseEditProductModelModal = () => {
    setEditProductModelModalOpen(false);
    setSelectedProductModel(null);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetModelsKey],
      refetchType: "all",
    });
  }, [page, limit, sortQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: "0px" }}>
            Product Models
          </Typography.Title>

          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={handleOpenCreateProductModelModal}
          >
            Add new
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: "12px 0" }}></Row>
      <Row>
        <Col xs={24}>
          <Table
            style={{ height: "400px" }}
            scroll={{ x: "max-content" }}
            columns={columns}
            key={"user-list"}
            //@ts-ignore
            dataSource={data?.items}
            rowKey={(item) => item.idModel}
            loading={isLoading}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateProductModelModal
        isOpen={createProductModelModalOpen}
        onClose={handleCloseCreateProductModelModal}
        queryClient={queryClient}
      />

      <EditProductModelModal
        isOpen={editProductModelModalOpen && !!selectedProductModel}
        onClose={handleCloseEditProductModelModal}
        queryClient={queryClient}
        modelData={selectedProductModel}
      />
    </motion.div>
  );
};

export default ProductModelSettingsPage;
