import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Table, Select, Button, InputNumber, Space, message, notification } from 'antd';
import { QueryClient } from '@tanstack/react-query';
import { useInventoryServiceCreateDeliveryNote, useInventoryServiceGetLocations, useInventoryServiceGetPurchaseOrderKey } from 'api/queries';
import qs from 'qs';

interface Product {
  id: string;
  name: string;
  quantity: number;
}

interface DeliveryItem extends Product {
  key: string;
  location: string | null;
  originalQuantity: number;
}

interface DeliveryNoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
  data: any;
}

const DeliveryNoteModal: React.FC<DeliveryNoteModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  data,
}) => {
  const [form] = Form.useForm();
  const [deliveryItems, setDeliveryItems] = useState<DeliveryItem[]>([]);

  // Fetch locations using React Query
  const { data: locations } = useInventoryServiceGetLocations({
    filters: "",
    pagination: qs.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  const { mutate, isPending } = useInventoryServiceCreateDeliveryNote({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetPurchaseOrderKey],
      });
      onClose();
      form.resetFields();
    },
    onError: (error: any) => {
      notification.error({
        message: "Failed to create delivery note",
        description: error?.body?.message || error?.response?.data?.message || error?.message,
      });
    },
  });

  const handleSubmit = (values: any) => {
    //check if any of the items don't have a location or quantity
    if (deliveryItems.some((item) => !item.location || !item.quantity)) {
      message.error("Please select a location and quantity for each item");
      return;
    }

    //check if any of the items don't have a quantity
    if (deliveryItems.some((item) => !item.quantity)) {
      message.error("Please enter a quantity for each item");
      return;
    }

    //check if any of the items don't have a location
    if (deliveryItems.some((item) => !item.location)) {
      message.error("Please select a location for each item");
      return;
    }

    //check if any of the items don't have a name
    if (deliveryItems.some((item) => !item.name)) {
      message.error("Please enter a name for each item");
      return;
    }
    
    mutate({
      purchaseOrderId: data?.idPurchaseOrder,
      requestBody: {
        deliveryNoteItems: deliveryItems,
        ...values,
      },
    });
  };

  const columns = [
    { title: 'Product', dataIndex: 'name', key: 'name' },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_: any, record: DeliveryItem) => (
        <InputNumber
          min={1}
          max={getMaxQuantity(record)}
          value={record.quantity}
          onChange={(value) => handleQuantityChange(record.key, value)}
        />
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (_: any, record: DeliveryItem) => (
        <Select
          style={{ width: '100%' }}
          onChange={(value) => handleLocationChange(record.key, value)}
          value={record.location}
        >
          {(locations?.items || []).map((loc) => (
            <Select.Option key={loc.idLocation} value={loc.idLocation}>
              {loc.locationName}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: any, record: DeliveryItem) => (
        <Space>
          <Button onClick={() => handleSplitItem(record.key)} disabled={record.quantity <= 1}>
            Split
          </Button>
          <Button onClick={() => handleUnsplitItem(record.id)} disabled={!canUnsplit(record.id)}>
            Unsplit
          </Button>
        </Space>
      ),
    },
  ];

  const getMaxQuantity = (record: DeliveryItem) => {
    const sameProductItems = deliveryItems.filter(item => item.id === record.id);
    const totalQuantity = sameProductItems.reduce((sum, item) => sum + item.originalQuantity, 0);
    return totalQuantity - (sameProductItems.filter(item => item.key !== record.key).reduce((sum, item) => sum + item.quantity, 0));
  };

  const handleLocationChange = (key: string, location: string) => {
    setDeliveryItems((prev) =>
      prev.map((item) =>
        item.key === key ? { ...item, location } : item
      )
    );
  };

  const handleQuantityChange = (key: string, newQuantity: number | null) => {
    if (newQuantity === null) return;
  
    setDeliveryItems((prev) => {
      const itemToUpdate = prev.find((item) => item.key === key);
      if (!itemToUpdate) return prev;
  
      const sameProductItems = prev.filter((item) => item.id === itemToUpdate.id);
      const totalQuantity = itemToUpdate.originalQuantity;
  
      if (sameProductItems.length === 1) {
        // If there's only one item, just update its quantity
        return prev.map((item) =>
          item.key === key ? { ...item, quantity: totalQuantity } : item
        );
      }
  
      // Calculate the current total quantity for this product
      const currentTotalQuantity = sameProductItems.reduce((sum, item) => sum + item.quantity, 0);
  
      // Calculate the maximum allowed new quantity
      const maxNewQuantity = Math.min(newQuantity, totalQuantity - (sameProductItems.length - 1));
  
      // Calculate the quantity to distribute among other items
      const remainingQuantity = totalQuantity - maxNewQuantity;
  
      // Distribute the remaining quantity among other items
      const otherItems = sameProductItems.filter((item) => item.key !== key);
      const distributedQuantities = distributeQuantity(remainingQuantity, otherItems.length);
  
      let distributionIndex = 0;
      return prev.map((item) => {
        if (item.key === key) {
          return { ...item, quantity: maxNewQuantity };
        }
        if (item.id === itemToUpdate.id) {
          return { ...item, quantity: distributedQuantities[distributionIndex++] };
        }
        return item;
      });
    });
  };
  
  // Helper function to distribute quantity among items
  const distributeQuantity = (quantity: number, itemCount: number): number[] => {
    const baseQuantity = Math.floor(quantity / itemCount);
    const remainder = quantity % itemCount;
    return Array(itemCount).fill(baseQuantity).map((q, index) => index < remainder ? q + 1 : q);
  };

  const handleSplitItem = (key: string) => {
    setDeliveryItems((prev) => {
      const itemToSplit = prev.find((item) => item.key === key);
      if (!itemToSplit || itemToSplit.quantity <= 1) return prev;

      const newQuantity = Math.floor(itemToSplit.quantity / 2);
      const newItem: DeliveryItem = {
        ...itemToSplit,
        key: Date.now().toString(),
        quantity: newQuantity,
        location: null,
      };

      return prev.map((item) =>
        item.key === key
          ? { ...item, quantity: item.quantity - newQuantity }
          : item
      ).concat(newItem);
    });
  };

  const canUnsplit = (id: string) => {
    return deliveryItems.filter(item => item.id === id).length > 1;
  };

  const handleUnsplitItem = (id: string) => {
    setDeliveryItems((prev) => {
      const itemsToUnsplit = prev.filter(item => item.id === id);
      if (itemsToUnsplit.length <= 1) return prev;

      const totalQuantity = itemsToUnsplit.reduce((sum, item) => sum + item.quantity, 0);
      const firstItem = itemsToUnsplit[0];

      return prev.filter(item => item.id !== id).concat({
        ...firstItem,
        quantity: totalQuantity,
      });
    });
  };

  useEffect(() => {
    if (data) {
      const deliveryItems = data?.purchaseOrderItems.map((item: any, index: number) => ({
        id: item.product.idProduct,
        quantity: item.quantity,
        name: item.product.productName,
        key: item.product.idProduct,
        originalQuantity: item.quantity,
        location: null,
        product: item.product.idProduct
      }));

      setDeliveryItems(deliveryItems);
    } else {
      setDeliveryItems([]);
      form.resetFields();
    }
  }, [data]);

  return (
    <Modal
      title="Create Delivery Note"
      open={isOpen}
      onCancel={onClose}
      width={800}
      onOk={form.submit}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} disabled={isPending}>
        <Form.Item name="entryDate" label="Delivery Date" rules={[{ required: true }]}>
          <Input type="date" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        dataSource={deliveryItems}
        pagination={false}
      />
    </Modal>
  );
};

export default DeliveryNoteModal;