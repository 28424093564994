import { Layout, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import LayoutBreadCrumbs from "./BreadCrumbs";

const { useToken } = theme;

const PageWrapper = () => {
  const { token } = useToken();
  return (
    <Layout
      style={{
        padding: "0 24px 24px",
        height: "100%",
        overflow: "auto",
        background: token.colorBgLayout,
      }}
    >
      <LayoutBreadCrumbs />
      <div style={{ display: "block", minHeight: "calc(100% - 70px)"}}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            height: "auto",
            minHeight: "100%",
            display: "block",
            background: token.colorBgBase,
            borderRadius: token.borderRadius,
          }}
        >
          <Outlet />
        </Content>
        <div style={{ width: "100%", height: "20px" }}></div>
      </div>
    </Layout>
  );
};

export default PageWrapper;
