import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Pagination, Spin, Table } from "antd";
import { useInventoryReportsServiceGetInventoryStockReport } from "api/queries";
import React from "react";

const ReportsPage = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  const { data: inventoryStockReport, isLoading } =
    useInventoryReportsServiceGetInventoryStockReport();

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      sorter: true,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "locationName",
    },
    {
      title: "Quantity on Hand",
      dataIndex: "quantityOnHand",
      sorter: true,
    },
    {
      title: "Stock Value",
      dataIndex: "stockValue",
      sorter: true,
      render: (value: number) => `$${value.toFixed(2)}`, // Format as currency
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
        

      <Spin spinning={isLoading}>
        <Table
          columns={columns}
          dataSource={inventoryStockReport?.items}
          rowKey="productId"
          pagination={false}
          style={{ background: "#fff" }}
        />
      </Spin>

      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Pagination
          current={currentPage}
          pageSize={10}
          total={total}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default ReportsPage;
