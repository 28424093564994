import {
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ServiceItem } from "api/requests";
import {
  useInventoryServiceGetServices,
  useInventoryServiceGetServicesKey,
} from "api/queries";
import queryString from "query-string";
import { useQueryClient } from "@tanstack/react-query";
import { CreateServiceModal } from "./CreateServiceModal";

export const ServiceListingPage = () => {
  const [createServiceModalOpen, setCreateServiceModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const queryClient = useQueryClient();

  const { data, isLoading, isPending, isFetching } =
    useInventoryServiceGetServices(
      {
        filters: "",
        pagination: queryString.stringify({
          offset: (page - 1) * limit,
          limit,
        }),
        sort: "",
      },
      [useInventoryServiceGetServicesKey]
    );

  const columns: TableColumnType<ServiceItem>[] = [
    {
      key: "serviceName",
      title: "Name",
      dataIndex: "serviceName",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },
    {
      key: "serviceCategory",
      title: "Service Category",
      dataIndex: "serviceCategory",
      render: (value) => value.serviceCategoryName,
    },
    {
      key: "isActive",
      title: "Is Active",
      dataIndex: "isActive",
      render: (value) => (value ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetServicesKey],
    });
  }, [page, limit]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Services
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setCreateServiceModalOpen(true)}
            >
              Create
            </Button>
          </Flex>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            key={"service-list"}
            rowKey={(record) => record.idService}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              onChange: (page) => setPage(page),
              onShowSizeChange: (_, limit) => setLimit(limit),
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Col>
      </Row>
      <CreateServiceModal
        isOpen={createServiceModalOpen}
        onClose={() => setCreateServiceModalOpen(false)}
        queryClient={queryClient}
      />
    </motion.div>
  );
};

export default ServiceListingPage;
