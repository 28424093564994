import { Tag } from "antd";

const PurchaseOrderDeliveryStatusTag = ({ deliveryStatus }: any) => {
  switch (deliveryStatus) {
    case "PENDING":
      return <Tag color="orange">Pending</Tag>;
    case "DELIVERED":
      return <Tag color="green">Delivered</Tag>;
    case "CANCELLED": 
      return <Tag color="red">Cancelled</Tag>;
    case "ON_ROUTE":
      return <Tag color="gold">On Route</Tag>;
    case "PARTIALLY_RECEIVED":
      return <Tag color="gold">Partially Received</Tag>;
    default: 
      return <Tag color="grey">Unknown</Tag>;
  }
};

export default PurchaseOrderDeliveryStatusTag;