import { Col, Menu, Row, Typography } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
const SettingsPage = () => {
  const nav = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={6}>
              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                style={{ height: "100%", overflow: "auto" }}
                items={[
                  // {
                  //   key: "Accounting",
                  //   label: "Accounting",
                  //   onClick: () => nav("/settings/accounting"),
                  // },
                  // {
                  //   key: "Users",
                  //   label: "Users",
                  //   onClick: () => nav("/settings/users"),
                  // },
                  {
                    key: "Company Details",
                    label: "Company Details",
                    onClick: () => nav("/settings/company-details"),
                  },
                  {
                    key: "Roles",
                    label: "Roles",
                    onClick: () => nav("/settings/roles"),
                  },
                  {
                    key: "Product Variants",
                    label: "Product Variants",
                    onClick: () => nav("/settings/product-variants"),
                  },
                  {
                    key: "Product Models",
                    label: "Product Models",
                    onClick: () => nav("/settings/product-models"),
                  },
                  {
                    key: "Backup",
                    label: "Backup",
                    onClick: () => nav("/settings/backup"),
                  }
                  // {
                  //   key: "Vendors",
                  //   label: "Vendors",
                  //   onClick: () => nav("/settings/vendors"),
                  // },
                  // {
                  //   key: "inventory",
                  //   label: "Inventory",
                  //   onClick: () => nav("/settings/inventory"),
                  // },
                ]}
              />
            </Col>
            <Col xs={18} style={{ paddingLeft: "1rem", overflow: "auto" }}>
              <Outlet />
            </Col>
          </Row>
        </Col>
      </Row>
    </motion.div>
  );
};

export default SettingsPage;
