import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import { useInventoryServiceGetModels, useInventoryServiceGetModelsKey } from "api/queries";
import { IGetModelsResponse } from "api/requests";

import queryString from "qs";
import { useEffect, useState } from "react";

interface IProductModelSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IGetModelsResponse[];
  selectProps?: Partial<SelectProps>;
}

const ProductModelSelectWithForm: React.FC<IProductModelSelectProps> = ({
  isForm,
  onChange,
  value,
  items,
  selectProps,
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetModels(
      {
        filters,
        pagination: queryString.stringify({ limit: 1000, offset: 0 }),
        sort: "",
      },
      [useInventoryServiceGetModelsKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            modelName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a model"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.modelName,
            value: _v.idModel,
            key:
              _v.modelName + _v.idModel + "product-model-select-product-model",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...selectProps}
    />
  );
};

const ProductModelSelectNoForm: React.FC<IProductModelSelectProps> = ({
  isForm,
  onChange,
  value,
  items,
  selectProps,
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetModels(
      {
        filters,
        pagination: queryString.stringify({ limit: 1000, offset: 0 }),
        sort: "",
      },
      [useInventoryServiceGetModelsKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            modelName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a model"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.modelName,
            value: _v.idModel,
            key:
              _v.modelName + _v.idModel + "product-model-select-product-model",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...selectProps}
    />
  );
};

export default function ProductModelSelect({
  isForm,
  onChange,
  value,
  items,
  selectProps,
}: IProductModelSelectProps) {
  if (isForm) {
    return <ProductModelSelectWithForm {...{ isForm, onChange, value, items, selectProps }} />;
  }
  return <ProductModelSelectNoForm {...{ isForm, onChange, value, items, selectProps }} />;
}