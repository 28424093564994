import { motion } from "framer-motion";
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
// import { useAccountingServiceGetBanks } from "api/queries";
import queryString from "query-string";
import { useQueryClient } from "@tanstack/react-query";
import { PlusCircleOutlined } from "@ant-design/icons";
import { TableActions } from "components/common";
import {
  useAccountingServiceDeleteBankAccount,
  useAccountingServiceGetBankAccounts,
} from "api/queries";
import { IBankAccountItem } from "api/requests";
import CreateBankAccountModal from "./CreateBankAccountModal";
import { IconBuildingBank } from "@tabler/icons-react";
import TableStatus from "components/common/TableStatus";
import EditBankAccountModal from "./EditBankAccountModal";

const BanksListingPage = () => {
  const [createBankModalOpen, setCreateBankModalOpen] = useState(false);
  const [editBankAccountModalOpen, setEditBankAccountModalOpen] =
    useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<any>(null);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [filtersQuery, setFiltersQuery] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isPending, isRefetching, refetch } =
    useAccountingServiceGetBankAccounts({
      filters: filtersQuery,
      pagination: queryString.stringify({
        offset: (currentPage - 1) * limit,
        limit,
      }),
      sort: sortQuery,
    });

  const { mutate: deleteBankAccountMutation, isPending: isDeletePending } =
    useAccountingServiceDeleteBankAccount({
      onSuccess: () => {
        message.success("Bank account deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleCreateBankModalClose = (success?: boolean) => {
    setCreateBankModalOpen(false);
  };

  const handleCreateBankModalOpen = () => {
    setCreateBankModalOpen(true);
  };

  const handleEditBankAccountModalOpen = (bankAccount: any) => {
    setEditBankAccountModalOpen(true);
    setSelectedBankAccount(bankAccount);
  };

  const handleEditBankAccountModalClose = () => {
    setEditBankAccountModalOpen(false);
  };

  useEffect(() => {
    refetch();
  }, [sortQuery, limit, currentPage]);

  const handleDeleteBankAccount = async (id: string) => {
    await deleteBankAccountMutation({
      id,
    });
    refetch();
  };

  const columns: TableColumnType<IBankAccountItem>[] = [
    {
      title: "Bank Name",
      dataIndex: "bankAccountName",
      key: "bankAccountName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Account Name",
      dataIndex: "bankAccountName",
      key: "bankAccountName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Account Number",
      dataIndex: "bankAccountNumber",
      key: "bankAccountNumber",
      width: "auto",
      sorter: true,
    },
    {
      title: "Account Type",
      dataIndex: "bankAccountType",
      key: "bankAccountType",
      width: "auto",
      sorter: true,
    },
    {
      title: "Account Status",
      dataIndex: "isActive",
      key: "isActive",
      width: "auto",
      render: (status) => {
        return <TableStatus status={status} />;
      },
      sorter: true,
    },
    {
      title: "Balance",
      dataIndex: "bankAccountBalance",
      key: "bankAccountBalance",
      width: "auto",
      //@ts-ignore
      render: (balance: number, _record) => `${_record.currency?.currencySymbol} ${balance.toFixed(2)}`,
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_i: any, row: any) => (
        <TableActions
          onEdit={() => handleEditBankAccountModalOpen(row)}
          onDeletePopConfirm={() => handleDeleteBankAccount(row.idBankAccount)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This bank account will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
      width: "auto",
    },
  ];

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setCurrentPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title>
            <IconBuildingBank className="anticon" style={{ marginRight: 8 }} />
            Banks
          </Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={handleCreateBankModalOpen}
          >
            Add New
          </Button>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} key={"bank-account-divider"} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            loading={isLoading || isRefetching || isRefetching || isPending}
            columns={columns}
            dataSource={data?.items}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={onTableChange}
            key={"bank-account-list"}
            rowKey={(record) => record.idBankAccount}
          />
        </Col>
        <CreateBankAccountModal
          isOpen={createBankModalOpen}
          onClose={handleCreateBankModalClose}
          queryClient={queryClient}
        />

        <EditBankAccountModal
          isOpen={editBankAccountModalOpen}
          onClose={handleEditBankAccountModalClose}
          queryClient={queryClient}
          defaultValues={selectedBankAccount}
        />
      </Row>
    </motion.div>
  );
};

export default BanksListingPage;
