import { useQueryClient } from "@tanstack/react-query";
import {
  Alert,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { useEffect, useState } from "react";
import {
  useUsersServiceGetRoles,
  useUsersServiceGetUsersKey,
} from "api/queries";
import { useUsersServiceUpdateUser } from "api/queries";
import QueryString from "qs";

export default function EditUserDialog(props: any) {
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const [formRef] = Form.useForm();

  const { mutate: updateUserMutation, isPending, isError } = useUsersServiceUpdateUser({
    onError: (error: any) => {
      setError(error?.body?.message || error?.message || error);
    },
    onSuccess: async (data) => {
      setError(null);
      await queryClient.invalidateQueries({
        queryKey: [useUsersServiceGetUsersKey],
        refetchType: "all",
      });
      props.onClose();
      formRef.resetFields();
    },
  });

  const { data: roleData, isLoading, isRefetching } = useUsersServiceGetRoles({
    filters: "",
    pagination: QueryString.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  const handleSubmit = (values: any) => {
    updateUserMutation({
      userId: props.defaultValues.idUser,
      requestBody: {
        userName: values.userName || props.defaultValues.userName,
        userFullName: values.userFullName || props.defaultValues.userFullName,
        userEmail: values.userEmail || props.defaultValues.userEmail,
        userPassword: values.userPassword || props.defaultValues.userPassword,
        userPhone: values.userPhone || props.defaultValues.userPhone,
        userAddress: values.userAddress || props.defaultValues.userAddress,
        isActive: values.isActive || props.defaultValues.isActive,
        userRole: values.role || props.defaultValues.role.idRole,
      },
    });
  };

  useEffect(() => {
    if (props.defaultValues) {
      formRef.setFieldsValue({
        userName: props.defaultValues.userName,
        userFullName: props.defaultValues.userFullName,
        userEmail: props.defaultValues.userEmail,
        userPassword: props.defaultValues.userPassword,
        userPhone: props.defaultValues.userPhone,
        userAddress: props.defaultValues.userAddress,
        isActive: props.defaultValues.isActive,
        role: props.defaultValues.role.idRole,
      });
    } else {
      formRef.resetFields();
    }
  }, [props.isOpen]);

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      title="Edit User"
      onOk={formRef.submit}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={formRef}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-user-form"
      >
        <Row gutter={16}>
          {isError && (
            <Col xs={24}>
              <Alert
                type="error"
                closable
                message="Error"
                description={error}
              />
            </Col>
          )}
          
          <Col xs={24}>
            <Form.Item
              name="userName"
              label="User Name"
              rules={[{ required: true, message: 'User name is required' }]}
            >
              <Input placeholder="Enter user name" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="userFullName"
              label="User Full Name"
              rules={[{ required: true, message: 'Full name is required' }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="userEmail"
              label="Email"
              rules={[{ required: true, type: 'email', message: 'Valid email is required' }]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="userPhone"
              label="Phone"
              rules={[{ required: true, message: 'Phone number is required' }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="userAddress"
              label="Address"
              rules={[{ required: true, message: 'Address is required' }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="isActive"
              label="Is Active"
              rules={[{ required: true, message: 'Status is required' }]}
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true, message: 'Role is required' }]}
            >
              <Select
                loading={isLoading || isRefetching}
                options={roleData?.items?.map((role) => ({
                  value: role.idRole,
                  label: role.roleName,
                }))}
                placeholder="Select a role"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
