import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import {
  useVendorServiceGetAllVendors,
  useVendorServiceGetAllVendorsKey,
} from "api/queries";
import { IVendor } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface IVendorSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IVendor[];
  selectProps?: Partial<SelectProps>;
}

const VendorSelectWithForm: React.FC<IVendorSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const {} = useVendorServiceGetAllVendors({
    filters: "",
    pagination: "",
    sort: "",
  });

  return <Select value={value} onChange={onChange}  {...rest.selectProps} />;
};

const VendorSelectNoForm: React.FC<IVendorSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching } = useVendorServiceGetAllVendors(
    {
      filters,
      pagination: "",
      sort: "",
    },
    [useVendorServiceGetAllVendorsKey]
  );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({ vendorName: { $ilike: `${value}%` } })
        : ""
    );
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useVendorServiceGetAllVendorsKey],
    });
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        const vend = data?.items?.find((i) => i.idVendor === v);
        //@ts-ignore
        onChange(vend);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a vendor"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return { label: _v.vendorName, value: _v.idVendor, key: _v.idVendor + "vendor-id-select-vendor2" };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const VendorSelect: React.FC<IVendorSelectProps> = ({ isForm, ...rest }) => {
  if (isForm) {
    return <VendorSelectWithForm {...rest} />;
  }
  return <VendorSelectNoForm {...rest} />;
};

export default VendorSelect;
