import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";

const LayoutBreadCrumbs = () => {
  const location = useLocation();

  return (
    <Breadcrumb
      items={location.pathname.split("/")?.map((item, index) => {
        return { path: `/${item}`, breadcrumbName: item, key: index + "breadcrumb" };
      })}
      style={{ margin: "16px 0" }}
    />
  );
};

export default LayoutBreadCrumbs;
