import { IconMenu2 } from "@tabler/icons-react";
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Layout,
  theme,
  Typography,
} from "antd";
import useAuth from "hooks/UseAuth";
const { Header } = Layout;

const { useToken } = theme;
export const LayoutHeader = ({
  handleCollapse,
}: {
  handleCollapse: () => void;
}) => {
  const { token } = useToken();
  const auth = useAuth();

  const handleLogout = () => {
    auth.logout();
  };

  return (
    <Header
      style={{
        display: "flex",
        height: "70px",
        background: token.colorBgBase,
        maxHeight: "70px",
        borderBottom: `1px solid ${token.colorBorder}`,
        padding: "1rem 2rem",
      }}
    >
      <Flex
        justify="space-between"
        align="center"
        flex={1}
        color="warning"
        style={{ background: token.colorBgBase }}
      >
        <div className="logo" style={{ height: "100%" }}>
          <img
            src={"/header-logo.png"}
            alt="logo"
            height={"100%"}
            width={"auto"}
          />
        </div>

        <Flex justify="center" align="flex-end">
          <Button
            icon={<IconMenu2 size={15} />}
            style={{ margin: "0 1rem" }}
            onClick={handleCollapse}
          ></Button>
          <Dropdown
            menu={{
              items: [
                {
                  title: "Logout",
                  onClick: handleLogout,
                  style: { userSelect: "none", cursor: "pointer" },
                  key: "logout",
                  label: <Typography.Link>Logout</Typography.Link>,
                },
              ],
            }}
          >
            <Avatar style={{ userSelect: "none", cursor: "pointer" }}>A</Avatar>
          </Dropdown>
        </Flex>
      </Flex>
    </Header>
  );
};
