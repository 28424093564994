import { Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select } from "antd";
import { useAccountingServiceCreateExpense, useAccountingServiceGetExpenseCategories, useAccountingServiceGetExpensesKey, useAccountingServiceGetPaymentAccounts } from "api/queries";
import qs from "qs";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

interface ICreateExpenseModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
}

const CreateExpenseModal: React.FC<ICreateExpenseModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [filters, setFilters] = useState("");
  const [categoryFilters, setCategoryFilters] = useState("");

  const [form] = Form.useForm();

  const { data, isLoading, isRefetching, refetch } = useAccountingServiceGetPaymentAccounts({
    filters,
    pagination: qs.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  const { data: categoryData, isLoading: isCategoryLoading, isRefetching: isCategoryRefetching, refetch: refetchCategory } = useAccountingServiceGetExpenseCategories({
    filters: categoryFilters,
    pagination: qs.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  const { mutate, isPending } = useAccountingServiceCreateExpense({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetExpensesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to create expense");
    },
  });

  const handleSubmit = (values: any) => {
    mutate({ requestBody: values });
  };

  const handleSearch = (value: string) => {
    setFilters(
      qs.stringify({
        accountName: {
          $ilike: `${value}%`,
        },
      })
    );
  };

  const handleCategorySearch = (value: string) => {
    setCategoryFilters(
      qs.stringify({
        name: {
          $ilike: `${value}%`,
        },
      })
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  useEffect(() => {
    refetchCategory();
  }, [categoryFilters]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Expense"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="expenseName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="expenseAmount"
              label="Amount"
              rules={[{ required: true }]}
            >
              <InputNumber min={0.01} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="expenseDate"
              label="Date"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="paymentAccount"
              label="Payment Account"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                loading={isLoading || isRefetching}
                options={data?.items?.map((item) => ({
                  label: item.accountName,
                  value: item.idAccount,
                }))}
                filterOption={false}
                notFoundContent={false}
                onSearch={handleSearch}
                allowClear
                onClear={() => setFilters("")}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="expenseCategory"
              label="Category"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                loading={isCategoryLoading || isCategoryRefetching}
                options={categoryData?.items?.map((item) => ({
                  label: item.name,
                  value: item.idExpenseCategory,
                }))}
                filterOption={false}
                notFoundContent={false}
                onSearch={handleCategorySearch}
                allowClear
                onClear={() => setCategoryFilters("")}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="expenseDescription"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateExpenseModal;