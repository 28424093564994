import { Alert, Col, Form, Modal, Row, Select } from "antd";
import { useInventoryServiceGetPurchaseOrderKey, useInventoryServiceUpdatePurchaseDeliveryStatus } from "api/queries";
import { useEffect } from "react";

interface IUpdateDeliveryStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  data: any;
}

export default function UpdateDeliveryStatusModal(
  props: IUpdateDeliveryStatusModalProps
) {
  const [form] = Form.useForm();

  const { mutate, isPending, isError, error } =
    useInventoryServiceUpdatePurchaseDeliveryStatus({
      onError: (error: any) => {
        console.dir(error);
      },
      onSuccess: async (data) => {
        await props.queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetPurchaseOrderKey],
          refetchType: "all",
        });
        props.onClose();
      },
    });

  useEffect(() => {
    //@ts-ignore
    form.setFieldsValue({ deliveryStatus: props.data?.deliveryStatus });
  }, [form, props.data]);

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      purchaseOrderId: props.data?.idPurchaseOrder,
    });
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      title="Update Delivery Status"
      onOk={form.submit}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="update-delivery-status-form"
      >
        <Row gutter={16}>
          {
            isError && (
              <Col xs={24}>
                <Alert
                  type="error"
                  closable
                  message={error?.message}
                  description={error?.body?.message || error?.message}
                />
              </Col>
            )
          }
          <Col xs={24}>
            <Form.Item
              name="deliveryStatus"
              label="Delivery Status"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Delivery Status"
                options={[
                  { label: "Pending", value: "PENDING" },
                  { label: "On Route", value: "ON_ROUTE" },
                  { label: "Delivered", value: "DELIVERED" },
                  { label: "Cancelled", value: "CANCELLED" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
