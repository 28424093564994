import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  Input,
  List,
  message,
  Modal,
  notification,
  Space,
} from "antd";
import {
  useSettingsServiceCreateProductVariantType,
  useSettingsServiceGetProductVariantTypesKey,
} from "api/queries";
import { useState, useEffect } from "react";

const CreateVariantTypeModal = ({
  isOpen,
  onClose,
  initialValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: any;
}) => {
  const [form] = Form.useForm();
  const [variantTypeValues, setVariantTypeValues] = useState<any[]>(
    initialValues?.variantTypeValues || []
  );
  const [inputValue, setInputValue] = useState("");

  const queryClient = useQueryClient();

  const { mutate: createVariantType, isPending } =
    useSettingsServiceCreateProductVariantType();

  // Handle Add variant value
  const handleAdd = () => {
    if (!inputValue.trim()) {
      message.error("Please enter a name before adding.");
      return;
    }
    if (variantTypeValues.some((val) => val.name === inputValue.trim())) {
      message.error("Name must be unique.");
      return;
    }

    setVariantTypeValues([
      ...variantTypeValues,
      { idProductVariant: null, name: inputValue.trim() },
    ]);
    setInputValue("");
  };

  // Handle Remove variant value
  const handleRemove = (index: number) => {
    const newVariantTypeValues = [...variantTypeValues];
    newVariantTypeValues.splice(index, 1);
    setVariantTypeValues(newVariantTypeValues);
  };

  // Submit handler
  const handleSubmit = async (values: any) => {
    const finalData = {
      ...values,
      variantTypeValues: variantTypeValues,
    };

    try {
      await createVariantType(
        {
          requestBody: finalData,
        },
        {
          onSuccess: () => {
            message.success("Variant Type created successfully.");
            onClose();
            queryClient.invalidateQueries({
              queryKey: [useSettingsServiceGetProductVariantTypesKey],
            });
            form.resetFields();
          },
          onError: (error: any) => {
            notification.error({
              message: "Error creating variant type.",
              description: error?.body?.message || error?.response?.data?.message || error?.message,
            });
          },
        }
      );
    } catch (err) {}
  };

  const handleClose = () => {
    form.resetFields();
    setVariantTypeValues(initialValues?.variantTypeValues || []);
    onClose();
  };

  useEffect(() => {
    if (initialValues) {
      setVariantTypeValues(initialValues?.productVariantValues || []);
    } else {
      setVariantTypeValues([]);
    }
  }, [initialValues]);

  return (
    <Modal
      title="Manage Variant"
      open={isOpen}
      onCancel={handleClose}
      onOk={form.submit}
      destroyOnClose
      confirmLoading={isPending}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Variant Type Name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Variant Values">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Enter variant value"
            />
            <Button
              onClick={handleAdd}
              disabled={!inputValue.trim()}
              type="primary"
            >
              Add Variant Value
            </Button>
          </Space>
        </Form.Item>

        <List
          dataSource={variantTypeValues}
          renderItem={(item, idx) => (
            <List.Item
              actions={[
                <Button danger onClick={() => handleRemove(idx)}>
                  Remove
                </Button>,
              ]}
            >
              {item.name}
            </List.Item>
          )}
        />
      </Form>
    </Modal>
  );
};

export default CreateVariantTypeModal;
