import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  user: "",
  role: "",
  company: "",
  permissions: "",
};

export const authSlice = createSlice({
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.company = action.payload.company;
      state.permissions = action.payload.permissions;
    },
  },
  name: "auth",
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;