import useAuth from '../hooks/UseAuth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = (props: any) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }
  return <Outlet />
};

export default ProtectedRoute;