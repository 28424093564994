import { useQueryClient } from "@tanstack/react-query";
import { Alert, Button, Col, Form, Input, Modal, Row, Switch } from "antd";
import { useVendorServiceCreateVendor } from "api/queries";
import CountrySelect from "components/common/CountrySelect";
import { useEffect, useState } from "react";

export default function CreateVendorDialog(props: any) {
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const [formRef] = Form.useForm();

  const { mutate: createVendorMutation, isPending } =
    useVendorServiceCreateVendor({
      onError: (error: any) => {
        console.dir(error);
        setError(error?.body?.message || error?.message || error);
      },
      onSuccess: async (data) => {
        setError(null);
        await queryClient.invalidateQueries({
          queryKey: ["VENDOR_LIST"],
          refetchType: "all",
        });
        props.onClose();
      },
      mutationKey: ["CREATE_VENDOR"],
    });

  const handleSubmit = (values: any) => {
    setError("");
    createVendorMutation({
      requestBody: {
        vendorName: values.vendorName,
        description: values.description,
        country: values.country,
        address: values.address,
        phone: values.phone,
        email: values.email,
        website: values.website,
        isActive: true,
      },
    });
  };

  useEffect(() => {
    formRef.resetFields();
    setError("");
  }, [props.isOpen]);

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      title="Create Vendor"
      footer={
        <>
          <Button key="back" disabled={isPending} onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => formRef.submit()}
            loading={isPending}
          >
            Submit
          </Button>
        </>
      }
    >
      <Form onFinish={handleSubmit} layout="vertical" form={formRef}>
        <Row>
          <Col xs={24}>
            {error && <Alert message={error} type="error" showIcon />}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Vendor Name"
              name="vendorName"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a vendor name" },
              ]}
            >
              <Input placeholder="Vendor name" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Description"
              name="description"
              validateTrigger="onChange"
            >
              <Input placeholder="Description" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Country"
              name="country"
              validateTrigger="onChange"
            >
              <CountrySelect withForm={true} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Address"
              name="address"
              validateTrigger="onChange"
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Phone"
              name="phone"
              validateTrigger="onChange"
            >
              <Input placeholder="Phone" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Email"
              name="email"
              validateTrigger="onChange"
              rules={[
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Website"
              name="website"
              validateTrigger="onChange"
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
