import { Col, Row, Timeline, Typography } from "antd";
import { IGetPurchaseOrderDetailsResponse } from "api/requests";
import { motion } from "framer-motion";
import dayjs from "dayjs";

const POHistory = ({ data }: { data?: IGetPurchaseOrderDetailsResponse }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24}>
          <Timeline
            mode="left"
            style={{ width: "100%", margin: "2rem 0" }}
            items={data?.purchaseOrderHistory?.map((item) => ({
              label: item?.title,
              children: (
                <>
                  <Typography.Text>
                    {item?.entryDate
                      ? dayjs(item?.entryDate).format("DD/MM/YYYY")
                      : ""}
                  </Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {/* @ts-ignore */}
                    {item?.createdBy?.userFullName}
                  </Typography.Text>
                </>
              ),
            }))}
          />
        </Col>
      </Row>
    </motion.div>
  );
};

export default POHistory;
