import { Tag } from "antd";
import { PurchaseOrderStatus } from "api/requests";

interface IPurchaseOrderStatusTagProps {
  status?: PurchaseOrderStatus;
}

export default function PurchaseOrderStatusTag({
  status,
}: IPurchaseOrderStatusTagProps) {
  if (status === "DRAFT") {
    return <Tag color="orange">Draft</Tag>;
  }

  if (status === "SENT") {
    return <Tag color="green">Sent</Tag>;
  }

  if (status === "APPROVED") {
    return <Tag color="blue">Approved</Tag>;
  }

  if (status === "CANCELLED") {
    return <Tag color="red">Cancelled</Tag>;
  }

  if (status === "ON_ROUTE") {
    return <Tag color="gold">On Route</Tag>;
  }

  if (status === "PARTIALLY_PAID") {
    return <Tag color="gold">Partially Paid</Tag>;
  }

  if (status === "FULLY_PAID") {
    return <Tag color="success">Paid</Tag>;
  }

  return <></>;
}
