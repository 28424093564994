import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { Typography } from "antd";

export default function TableStatus({ status }: { status: boolean }) {
  return (
    <>
      {status === true ? (
        <Typography.Text type="success" style={{ textAlign: "center" }}>
          <IconCircleCheck size={18} className="anticon" />
        </Typography.Text>
      ) : (
        <Typography.Text type="danger" style={{ textAlign: "center" }}>
          <IconCircleX size={18} className="anticon" />
        </Typography.Text>
      )}
    </>
  );
}
