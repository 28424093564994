import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useUsersServiceCreateUser,
  useUsersServiceGetRoles,
  useUsersServiceGetUsersKey,
} from "api/queries";
import QueryString from "qs";

export default function CreateUserDialog(props: any) {
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data: roleList, isLoading: rolesLoading } = useUsersServiceGetRoles({
    filters: "",
    pagination: QueryString.stringify({ limit: 100, offset: 0 }),
    sort: "",
  });

  const [formRef] = Form.useForm();

  const { mutate: createUser, isPending } = useUsersServiceCreateUser({
    onError: (error: any) => {
      console.dir(error);
      setError(error?.body?.message || error?.message || error);
    },
    onSuccess: async (data) => {
      setError(null);
      await queryClient.invalidateQueries({
        queryKey: [useUsersServiceGetUsersKey],
        refetchType: "all",
      });
      props.onClose();
    },
    mutationKey: ["CREATE_USER"],
  });

  const handleSubmit = (values: any) => {
    setError("");
    createUser({
      requestBody: {
        userFullName: values.userFullName,
        userName: values.userName,
        userPassword: values.password,
        userRole: values.role,
        userEmail: values.email,
        userPhone: values.mobileNumber,
        userAddress: values.address,
      },
    });
  };

  useEffect(() => {
    formRef.resetFields();
    setError("");
  }, [props.isOpen]);

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      title="Create User"
      footer={
        <>
          <Button key="back" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => formRef.submit()}
            loading={isPending}
          >
            Submit
          </Button>
        </>
      }
    >
      <Form onFinish={handleSubmit} layout="vertical" form={formRef}>
        <Row>
          <Col xs={24}>
            {error && <Alert message={error} type="error" showIcon />}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Full Name"
              name="userFullName"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a user full name" },
              ]}
            >
              <Input placeholder="user full name" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Email"
              name="email"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter an email" },
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input placeholder="email" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Mobile Number"
              name="mobileNumber"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a mobile number" },
              ]}
            >
              <Input placeholder="mobile number" type="number" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Username"
              name="userName"
              fieldId="username"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a username" },
                {
                  pattern: /^[a-zA-Z0-9]+$/i,
                  message: "The input is not valid username",
                },
              ]}
            >
              <Input placeholder="username" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Password"
              name="password"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a password" },
                { min: 6, message: "Password must be at least 6 characters" },
              ]}
            >
              <Input.Password placeholder="password" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Role"
              name="role"
              validateTrigger="onChange"
              rules={[{ required: true, message: "Please select a role" }]}
            >
              <Select
                loading={rolesLoading}
                options={roleList?.items?.map((role) => ({
                  value: role.idRole,
                  label: role.roleName,
                }))}
                placeholder="Select a role"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
