import {
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import {
  useAccountingServiceGetExpenseAccounts,
  useAccountingServiceGetExpenseCategoriesKey,
  useAccountingServiceUpdateExpenseCategory,
} from "api/queries";
import qs from "qs";
import { useEffect, useState } from "react";

interface IEditExpenseCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  defaultValues: any;
}

const EditExpenseCategoryModal: React.FC<IEditExpenseCategoryModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [filters, setFilters] = useState("");
  const [form] = Form.useForm();

  const { mutate, isPending } = useAccountingServiceUpdateExpenseCategory({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetExpenseCategoriesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to update expense category");
    },
  });

  const onFinish = (values: any) => {
    mutate({
      requestBody: values,
      idExpenseCategory: defaultValues.idExpenseCategory,
    });
  };

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetExpenseAccounts({
      filters,
      pagination: "",
      sort: "",
    });

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
      account: defaultValues?.account?.idAccount || 0,
      isActive: defaultValues?.isActive || false,
    });
  }, [form, defaultValues]);

  useEffect(() => {
    refetch();
  }, [filters]);

  const handleSearch = (value: string) => {
    setFilters(
      value
        ? qs.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={form.submit}
      confirmLoading={isPending}
      okText="Update"
      cancelText="Cancel"
      destroyOnClose
      afterClose={() => form.resetFields()}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        name="edit-expense-category-form"
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="account"
              label="Account"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select a account"
                options={data?.items?.map((item) => ({
                  label: item.accountName,
                  value: item.idAccount,
                  key: item.idAccount + "account-select-account",
                }))}
                allowClear
                loading={isLoading || isRefetching}
                onSearch={handleSearch}
                showSearch
                filterOption={false}
                notFoundContent={
                  isLoading || (data && data.total > 0) ? false : <Empty />
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="isActive"
              label="Is Active"
              rules={[{ required: true }]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditExpenseCategoryModal;
