import { Button, Col, Divider, Row, Space, Table, Typography } from "antd";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useAccountingServiceGetExpenses } from "api/queries";
import { TableActions } from "components/common";
import { motion } from "framer-motion";
import qs from "qs";
import CreateExpenseModal from "./CreateExpenseModal";
import { useQueryClient } from "@tanstack/react-query";
import EditExpenseModal from "./EditExpenseModal";
import useFilters, { FilterType } from "hooks/UseFilter";
import { render } from "@testing-library/react";
import dayjs from "dayjs";

const ExpenseListingPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [createExpenseModalOpen, setCreateExpenseModalOpen] = useState(false);
  const [updateExpenseModalOpen, setUpdateExpenseModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<any>(null);

  const queryClient = useQueryClient();

  const filtersDef: any[] = [
    {
      key: "expenseName",
      label: "Name",
      type: FilterType.Text,
    },
    {
      key: "expenseDescription",
      label: "Description",
      type: FilterType.Text,
    },
    {
      key: "expenseAmount",
      label: "Amount",
      type: FilterType.Slider,
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data } = useAccountingServiceGetExpenses({
    filters: filtersQuery,
    pagination: qs.stringify({
      offset: (page - 1) * limit,
      limit,
    }),
    sort: sortQuery,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "expenseName",
      key: "expenseName",
      sorter: true,
      width: "auto",
    },
    {
      title: "Description",
      dataIndex: "expenseDescription",
      key: "expenseDescription",
      sorter: true,
      width: "auto",
    },
    {
      title: "Amount",
      dataIndex: "expenseAmount",
      key: "expenseAmount",
      sorter: true,
      width: "auto",
    },
    {
      title: "Date",
      dataIndex: "expenseDate",
      key: "expenseDate",
      sorter: true,
      width: "auto",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_i: any, row: any) => (
        <TableActions
          onEdit={() => handleUpdateExpenseModalOpen(row)}
        />
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const handleCreateExpenseModalClose = (isSuccess?: boolean) => {
    setCreateExpenseModalOpen(false);
  };

  const handleCreateExpenseModalOpen = () => {
    setCreateExpenseModalOpen(true);
  };

  const handleUpdateExpenseModalOpen = (expense: any) => {
    setSelectedExpense(expense);
    setUpdateExpenseModalOpen(true);
  };

  const handleUpdateExpenseModalClose = () => {
    setSelectedExpense(null);
    setUpdateExpenseModalOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title>Expenses</Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Space>
            {FilterButton}
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={handleCreateExpenseModalOpen}
            >
              Create new
            </Button>
          </Space>
        </Col>

        <Col xs={24}>
          {Filters}
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            loading={false}
            columns={columns}
            dataSource={data?.items}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>

      <CreateExpenseModal
        isOpen={createExpenseModalOpen}
        onClose={handleCreateExpenseModalClose}
        queryClient={queryClient}
      />

      <EditExpenseModal
        isOpen={updateExpenseModalOpen && !!selectedExpense}
        onClose={handleUpdateExpenseModalClose}
        queryClient={queryClient}
        defaultValues={selectedExpense}
      />
    </motion.div>
  );
};

export default ExpenseListingPage;
