import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Switch,
} from "antd";
import {
  useAccountingServiceGetCurrenciesKey,
  useAccountingServiceUpdateCurrency,
} from "api/queries";
import { useEffect } from "react";

interface IEditCurrencyModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  defaultValues: any;
}

const EditCurrencyModal: React.FC<IEditCurrencyModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useAccountingServiceUpdateCurrency({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetCurrenciesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to update currency");
    },
  });

  const onFinish = (values: any) => {
    mutate({
      requestBody: values,
      id: defaultValues.idCurrency,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
      rate: defaultValues?.exchangeRate?.rate || 0,
    });
  }, [isOpen])

  return (
    <Modal
      title="Edit Currency"
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={form.submit}
      confirmLoading={isPending}
      okText="Update"
      cancelText="Cancel"
      destroyOnClose
      afterClose={() => form.resetFields()}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        name="edit-currency-form"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="currencyName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="currencyCode"
              label="Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="currencySymbol"
              label="Symbol"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="rate" label="Rate" rules={[{ required: true }]}>
              <InputNumber width={"100%"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="isActive"
              label="Is Active"
              rules={[{ required: true }]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditCurrencyModal;
