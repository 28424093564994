// src/StatementOfAccount.tsx
import React, { useState } from "react";
import {
  Table,
  Button,
  DatePicker,
  Typography,
  Row,
  Col,
  Space,
  Alert,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { motion } from "framer-motion";
import { useVendorsServiceGetVendorStatementOfAccount } from "api/queries";
import { IVendor } from "api/requests";
import StatementOfAccountPrintModal from "./StatementOfAccountPrintModal";

dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// Define the data structure for the statement entries
interface StatementEntry {
  key: string;
  date: string;
  description: string;
  amount: number;
  balance: number;
}

// Define the columns for the table
const columns: ColumnsType<StatementEntry> = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount) => (
      <Typography.Text>{amount.toLocaleString()}</Typography.Text>
    ),
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance) => (
      <Typography.Text>{balance.toLocaleString()}</Typography.Text>
    ),
  },
];

const { RangePicker } = DatePicker;

const StatementOfAccount: React.FC<{
  vendorId: number;
  vendorDetails: IVendor;
}> = ({ vendorId, vendorDetails }) => {
  const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>(null);
  const [showPrintModal, setShowPrintModal] = useState(false);

  const handleDateChange = (dates: [Dayjs, Dayjs] | null) => {
    if (!dates) {
      setSelectedDates(null);
      return;
    }
    setSelectedDates([dates[0].toDate(), dates[1].toDate()]);
  };

  const handlePrint = () => {
    setShowPrintModal(true);
  };

  const { data, isLoading, isError, error, isRefetching } =
    useVendorsServiceGetVendorStatementOfAccount({
      startDate: selectedDates?.[0]
        ? dayjs(selectedDates?.[0]).format("YYYY-MM-DD")
        : "",
      endDate: selectedDates?.[1]
        ? dayjs(selectedDates?.[1]).format("YYYY-MM-DD")
        : "",
      vendorId,
    });

  const predefinedRanges = {
    Today: [dayjs(), dayjs()],
    "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
    "This Month": [dayjs().startOf("month"), dayjs().endOf("month")],
    "This Year": [dayjs().startOf("year"), dayjs().endOf("year")],
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row style={{ margin: "2rem 0" }} gutter={16}>
        <Col xs={24}>
          <Space
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <RangePicker
              presets={Object.keys(predefinedRanges).map((key) => ({
                label: key,
                //@ts-ignore
                value: predefinedRanges[key],
              }))}
              onChange={(dates, dateStrings) =>
                handleDateChange(dates as [Dayjs, Dayjs] | null)
              }
            />

            <Button type="primary" onClick={handlePrint}>
              Print
            </Button>
          </Space>
        </Col>

        {isError && (
          <Col xs={24}>
            <Alert
              type="error"
              closable
              message={(error as any)?.message}
              description={
                (error as any)?.body?.message || (error as any)?.message
              }
            />
          </Col>
        )}

        {
          <Col xs={24} style={{ marginTop: "1rem" }}>
            <Table
              loading={isLoading || isRefetching}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </Col>
        }
      </Row>
      <StatementOfAccountPrintModal
        isOpen={showPrintModal}
        onClose={() => setShowPrintModal(false)}
        data={data || []}
        vendorName={vendorDetails?.vendorName}
        vendorAddress={vendorDetails?.address}
        startDate={selectedDates?.[0]}
        endDate={selectedDates?.[1]}
      />
    </motion.div>
  );
};

export default StatementOfAccount;
