import { QueryClient } from "@tanstack/react-query";
import { Alert, Button, Col, Form, Input, Modal, Row, Switch } from "antd";
import {
  useCustomerServiceCreateCustomer,
  useCustomerServiceGetCustomersKey,
} from "api/queries";
import CountrySelect from "components/common/CountrySelect";
import { useState } from "react";

interface ICreateCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}

const CreateCustomerModal: React.FC<ICreateCustomerModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [error, setError] = useState<string | null>(null);

  const [form] = Form.useForm();

  const { mutate, isPending } = useCustomerServiceCreateCustomer({
    onError: (error: any) => {
      setError(error?.body?.message || error?.message || error);
    },
    onSuccess: async (data) => {
      setError(null);
      await queryClient.invalidateQueries({
        queryKey: [useCustomerServiceGetCustomersKey],
        refetchType: "all",
      });
      form.resetFields();
      onClose();
    },
    mutationKey: ["CREATE_CUSTOMER"],
  });

  const handleSubmit = (values: any) => {
    setError("");
    mutate({
      requestBody: {
        customerName: values.customerName,
        description: values.description,
        country: values.country,
        address: values.address,
        phone: values.phone,
        email: values.email,
        website: values.website,
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Customer"
      destroyOnClose
      footer={
        <>
          <Button key="back" onClick={onClose}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => form.submit()}
            loading={isPending}
          >
            Submit
          </Button>
        </>
      }
    >
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
        disabled={isPending}
        autoComplete="off"
      >
        <Row>
          <Col xs={24}>
            {error && <Alert message={error} type="error" showIcon />}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Customer Name"
              name="customerName"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a customer name" },
              ]}
            >
              <Input placeholder="Customer name" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Country"
              name="country"
              validateTrigger="onChange"
            >
              <CountrySelect withForm={true} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Address"
              name="address"
              validateTrigger="onChange"
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Phone"
              name="phone"
              validateTrigger="onChange"
            >
              <Input placeholder="Phone" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Email"
              name="email"
              validateTrigger="onChange"
              rules={[
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Website"
              name="website"
              validateTrigger="onChange"
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              hasFeedback
              label="Description"
              name="description"
              validateTrigger="onChange"
            >
              <Input.TextArea rows={3} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateCustomerModal;
