import {
  Col,
  Divider,
  Flex,
  Result,
  Row,
  Segmented,
  Spin,
} from "antd";
import { useInventoryServiceGetProduct, useInventoryServiceGetProductKey } from "api/queries";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProductDetails from "./components/PorductDetails";
import { useState } from "react";
import ProductVariants from "./components/ProductVariants";

const ProductView = () => {
  const [currentOption, setCurrenctOption] = useState("details");

  const { id } = useParams();

  //@ts-ignore
  const company = useSelector((state) => state.company);

  const { data, isLoading, isError, error, failureReason, status, refetch } =
    useInventoryServiceGetProduct(
      {
        id: Number(id),
      },
      [useInventoryServiceGetProductKey],
      { retry: false }
    );

  if (isError) {
    console.dir("error", error);
    console.dir("abc", failureReason);
    return (
      <Result
        status="error"
        title={status}
        //@ts-ignore
        subTitle={
          //@ts-ignore
          failureReason?.body?.message
        }
      />
    );
  }

  if (isLoading && !isError) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5, delay: 0 }}
        style={{ minHeight: "100%", height: "100%" }}
      >
        <Flex
          style={{ width: "100%", height: "70vh" }}
          justify="center"
          align="center"
        >
          <Spin />
        </Flex>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Flex justify="center" align="flex-start" style={{ width: "100%" }}>
        <Row gutter={16} style={{ width: "100%" }}>
          <Col xs={24}>
            <Segmented
              options={[
                { label: "Product details", value: "details" },
                { label: "Product variants", value: "variants" },
              ]}
              value={currentOption}
              onChange={(value) => setCurrenctOption(value)}
            />
          </Col>

          <Col xs={24}>
            <Divider />
          </Col>

          {
            currentOption === "details" && <ProductDetails data={data} company={company} />
          }

          {
            currentOption === "variants" && <ProductVariants data={data} refetch={refetch} />
          }
        </Row>
      </Flex>
    </motion.div>
  );
};

export default ProductView;
