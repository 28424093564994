import { QueryClient } from "@tanstack/react-query";
import { Col, Form, Input, InputNumber, message, Modal, Row, Switch } from "antd";
import {
  useAccountingServiceCreateCurrency,
  useAccountingServiceGetCurrenciesKey,
} from "api/queries";
import { ICreateCurrencyInput } from "api/requests";

interface CreateCurrencyModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}

const CreateCurrencyModal: React.FC<CreateCurrencyModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useAccountingServiceCreateCurrency({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetCurrenciesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to create currency");
    },
  });

  const onFinish = (values: ICreateCurrencyInput) => {
    mutate({ requestBody: values });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Currency"
      onOk={() => form.submit()}
      confirmLoading={isPending}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="currencyName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="currencyCode"
              label="Code"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="currencySymbol"
              label="Symbol"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="rate"
              label="Rate"
              rules={[{ required: true }]}
            >
              <InputNumber width={"100%"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateCurrencyModal;
