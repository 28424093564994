import {
  Button,
  Col,
  Divider,
  Flex,
  message,
  Row,
  Space,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import queryString from "qs";
import { useQueryClient } from "@tanstack/react-query";
import {
  useInventoryServiceDeleteProductCategory,
  useInventoryServiceGetProductCategories,
  useInventoryServiceGetProductCategoriesKey,
} from "api/queries";
import { IProductCategoryItem } from "api/requests";
import { TableActions } from "components/common";
import { EditProductCategoryModal } from "./EditProductCategoryModal";
import { CreateCategoryModal } from "./CreateProductCategoryModal";
import useFilters, {
  FilterTemplate,
  FilterType,
  IFilterItem,
} from "hooks/UseFilter";

export const ProductCategoryListingPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createCategoryModalOpen, setCreateCategoryModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [sortQuery, setSortQuery] = useState("");

  const queryClient = useQueryClient();

  const filtersDef: IFilterItem[] = [
    {
      key: "productCategoryName",
      label: "Name",
      type: FilterType.Text,
    },
    {
      key: "productCategoryDescription",
      label: "Description",
      type: FilterType.Text,
    },
    {
      key: "parentCategory",
      label: "Parent Category",
      type: FilterType.template,
      template: FilterTemplate.ProductCategorySelect,
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isPending, isFetching, refetch } =
    useInventoryServiceGetProductCategories(
      {
        filters: queryString.stringify(filtersQuery),
        pagination: queryString.stringify({
          offset: (page - 1) * limit,
          limit,
        }),
        sort: sortQuery,
      },
      [useInventoryServiceGetProductCategoriesKey]
    );

  const { mutate: deleteProductCategoryMutation, isPending: isDeletePending } =
    useInventoryServiceDeleteProductCategory({
      onSuccess: () => {
        message.success("Product category deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleOpenEditProductCategoryModal = (productCategory: any) => {
    setEditCategoryModalOpen(true);
    setSelectedCategory(productCategory);
  };

  const handleCloseEditProductCategoryModal = () => {
    setEditCategoryModalOpen(false);
  };

  const handleDeleteProductCategory = async (id: string) => {
    await deleteProductCategoryMutation({
      idProductCategory: id,
    });
    refetch();
  };

  const columns: TableColumnType<IProductCategoryItem>[] = [
    {
      key: "productCategoryName",
      title: "Name",
      dataIndex: "productCategoryName",
      sorter: true,
      width: "auto",
    },
    {
      key: "productCategoryDescription",
      title: "Description",
      dataIndex: "productCategoryDescription",
      sorter: true,
      width: "auto",
    },
    {
      key: "parentCategory",
      title: "Parent Category",
      dataIndex: "parentCategory",
      sorter: true,
      render: (value: any) => value?.productCategoryName || "-",
      width: "auto",
    },
    {
      key: "isActive",
      title: "Is Active",
      dataIndex: "isActive",
      render: (value) => (value ? "Yes" : "No"),
      sorter: true,
      width: "auto",
    },
    {
      key: "idProductCategory",
      title: "Actions",
      dataIndex: "idProductCategory",
      render: (_r, record) => (
        <TableActions
          onEdit={() => handleOpenEditProductCategoryModal(record)}
          onDeletePopConfirm={() =>
            handleDeleteProductCategory(record.idProductCategory.toString())
          }
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This product category will delete permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
      width: "auto",
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetProductCategoriesKey],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortQuery, filtersQuery]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Product Categories
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Space>
              {FilterButton}
              <Button
                icon={<PlusCircleOutlined />}
                type="primary"
                onClick={() => setCreateCategoryModalOpen(true)}
              >
                Create
              </Button>
            </Space>
          </Flex>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            key={"product-list"}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              onChange: (page) => setPage(page),
              onShowSizeChange: (_, limit) => setLimit(limit),
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
            rowKey={(item) => item.idProductCategory}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
      <CreateCategoryModal
        isOpen={createCategoryModalOpen}
        onClose={() => setCreateCategoryModalOpen(false)}
        queryClient={queryClient}
      />

      <EditProductCategoryModal
        isOpen={editCategoryModalOpen}
        onClose={handleCloseEditProductCategoryModal}
        queryClient={queryClient}
        defaultValues={selectedCategory}
      />
    </motion.div>
  );
};

export default ProductCategoryListingPage;
