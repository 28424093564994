import AppRoutes from "./routes/routes";
import "./App.css";
import { ThemeProvider } from "utils/theme";

import { Provider } from "react-redux";
import store from "utils/redux/store";
import ability, { AbilityContext } from "utils/abilit";
import { QueryClientProviderComponent } from "utils/Providers/QueryClientProvider";

function App() {
  return (
    <div className="App">
      <QueryClientProviderComponent>
        <AbilityContext.Provider value={ability}>
          <ThemeProvider>
            <Provider store={store}>
              <AppRoutes />
            </Provider>
          </ThemeProvider>
        </AbilityContext.Provider>
      </QueryClientProviderComponent>
    </div>
  );
}

export default App;
