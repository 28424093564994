import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useAuth from "hooks/UseAuth";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

export const QueryClientProviderComponent = ({ children }: React.PropsWithChildren) => {
  const auth = useAuth();
  const location = useLocation();
  const nav = useNavigate();

  axios.interceptors.request.use((config) => {
    if (auth.token) {
      config.headers.Authorization = `Bearer ${auth.token}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err?.response?.status === 401 && location.pathname !== "/login") {
        auth.logout();
        nav("/login");
      }
      throw err;
    }
  );

  const queryClient = new QueryClient({});

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};