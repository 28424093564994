import { Form, Select, SelectProps } from "antd";
import { getCountryDataList } from "countries-list";

interface ICountrySelectProps {
  value?: string;
  onChange?: (value: string) => void;
  withForm?: boolean;
  selectProps?: Partial<SelectProps>;
}

const CountrySelect: React.FC<ICountrySelectProps> = ({
  value,
  onChange,
  withForm,
  selectProps,
}) => {
  if (withForm) {
    const countries = getCountryDataList();
    const { status, errors } = Form.Item.useStatus();
    return (
      <Select
        options={countries.map((c) => ({
          label: c.name,
          value: c.name,
          key: c.name + "country-select-country"
        }))}
        filterOption={(input, option: any) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        allowClear
        showSearch
        value={value}
        onChange={onChange}
        className={`custom-input-${status}`}
        placeholder={(errors.length && errors[0]) || ""}
        style={{ width: "100%" }}
        {...selectProps}
      />
    );
  } else {
    return (
      <Select
        options={getCountryDataList().map((c) => ({
          label: c.name,
          value: c.name,
          key: c.name + "country-select-country2"
        }))}
        filterOption={(input, option: any) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        allowClear
        showSearch
        value={value}
        onChange={onChange}
        style={{ width: "100%" }}
        {...selectProps}
      />
    );
  }
};

export default CountrySelect;
