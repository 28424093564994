import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Table,
  Space,
  InputNumber,
  Modal,
  Alert,
  Checkbox,
  Flex,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { QueryClient } from "@tanstack/react-query";
import { InvoiceStatus } from "components/common";
import CustomerSelect from "components/common/CustomerSelect";
import ProductSelect from "components/common/ProductSelect";
import QueryString from "qs";
import {
  useAccountingServiceGetTaxes,
  useInventoryServiceGetProducts,
  useSalesServiceCreateInvoice,
  useSalesServiceGetInvoicesKey,
} from "api/queries";
import dayjs from "dayjs";
import { ICreateInvoiceInput } from "api/requests";
import PaymentAccountSelect from "components/common/PaymentAccountSelect";
import TaxSelect from "components/common/TaxSelect";

const { Option } = Select;
const { TextArea } = Input;

interface Item {
  key: number;
  idProduct: string;
  description: string;
  price: number;
  quantity: number;
  discountType: "percentage" | "fixed";
  discountValue: number;
}

interface ItemOption {
  value: string;
  label: string;
  productPrice: number;
}

interface ICreateInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}

const InvoiceCreation: React.FC<ICreateInvoiceModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [form] = Form.useForm();
  const [items, setItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedTax, setSelectedTax] = useState<any | null>(null);

  const addItem = () => {
    setItems([
      ...items,
      {
        key: items.length,
        idProduct: "",
        description: "",
        price: 0,
        quantity: 1,
        discountType: "percentage",
        discountValue: 0,
      },
    ]);
  };

  const handleItemChange = (
    index: number,
    key: keyof Item,
    value: string | number
  ) => {
    const newItems: any = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };

  const handleRemoveItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const { data: productData } = useInventoryServiceGetProducts({
    filters: "",
    sort: "",
    pagination: QueryString.stringify({ limit: 1000, offset: 0 }),
  });

  const { data: taxData } = useAccountingServiceGetTaxes({
    filters: "",
    sort: "",
    pagination: QueryString.stringify({ limit: 1000, offset: 0 }),
  });

  const { mutate, isPending, isError, error } = useSalesServiceCreateInvoice({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useSalesServiceGetInvoicesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.body?.message || err || "Failed to create invoice");
    },
  });

  const columns: ColumnsType<Item> = [
    {
      title: "Item",
      dataIndex: "name",
      width: 200,
      render: (text, record, index) => (
        <ProductSelect
          isForm={false}
          selectProps={{
            style: { width: "100%" },
            value: record.idProduct,
            onChange: (value) => {
              handleItemChange(index, "idProduct", value);
              const selectedItem = productData?.items?.find(
                (item) => item.idProduct === value
              );
              if (selectedItem) {
                handleItemChange(
                  index,
                  "price",
                  selectedItem?.productPrice || 0
                );
                setSelectedItems([...selectedItems, value]);
              }
            },
          }}
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 200,
      render: (text, record, index) => (
        <Input.TextArea
          value={record.description}
          rows={1}
          onChange={(e) =>
            handleItemChange(index, "description", e.target.value)
          }
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 50,
      render: (text, record, index) => (
        <InputNumber
          min={0}
          value={record.price}
          onChange={(value) => handleItemChange(index, "price", value ?? 0)}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: 50,
      render: (text, record, index) => (
        <InputNumber
          min={1}
          value={record.quantity}
          onChange={(value) => handleItemChange(index, "quantity", value ?? 1)}
        />
      ),
    },
    {
      title: "Discount",
      dataIndex: "discountValue",
      width: 50,
      render: (text, record, index) => (
        <Space>
          <Select
            value={record.discountType}
            onChange={(value) => handleItemChange(index, "discountType", value)}
          >
            <Option value="percentage">%</Option>
            <Option value="fixed">$</Option>
          </Select>
          <InputNumber
            min={0}
            value={record.discountValue}
            onChange={(value) =>
              handleItemChange(index, "discountValue", value ?? 0)
            }
          />
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 50,
      render: (text, record, index) => (
        <Button onClick={() => handleRemoveItem(index)}>Remove</Button>
      ),
    },
  ];

  const calculateSubtotal = (): number => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateTotalDiscount = (): number => {
    return items.reduce((total, item) => {
      if (item.discountType === "percentage") {
        return total + (item.price * item.quantity * item.discountValue) / 100;
      }
      return total + item.discountValue;
    }, 0);
  };

  const calculateTotalTax = (): number => {
    const taxRate = selectedTax?.taxRate || 0;
    const taxType = selectedTax?.taxType || "PERCENTAGE";
    const Subtotal = calculateSubtotal();
    if (taxType === "PERCENTAGE") {
      return (Subtotal * (taxRate || 0)) / 100;
    }
    return taxRate;
  };

  const calculateTotal = (): number => {
    return calculateSubtotal() - calculateTotalDiscount() + calculateTotalTax();
  };

  const handleSubmit = (values: any) => {
    const invoiceData: ICreateInvoiceInput = {
      ...values,
      invoiceSubtotal: calculateSubtotal(),
      invoiceDiscountTotal: calculateTotalDiscount(),
      totalTax: calculateTotalTax(),
      invoiceTotalAmount: calculateTotal(),
      tax: selectedTax?.idTax || null,
      customer: values.customer,
      invoiceItems: items.map((item) => ({
        product: item.idProduct,
        quantity: item.quantity,
        unitPrice: item.price,
        totalPrice: item.price * item.quantity,
      })),
    };

    mutate({ requestBody: invoiceData });
  };

  const handleTaxChange = () => {
    const selectTax = form.getFieldValue("tax");

    if (!selectTax) {
      setSelectedTax(null);
      return;
    }

    const tax = taxData?.items?.find((i) => i.idTax === selectTax);

    if (tax) {
      setSelectedTax(tax);
      form.setFieldsValue({
        taxRate: tax.taxRate,
        taxType: tax.taxType,
      });
    } else {
      setSelectedTax(null);
      form.setFieldsValue({
        taxRate: 0,
        taxType: "percentage",
      });
    }
  };

  return (
    <Modal
      title="Create Invoice"
      open={isOpen}
      width={800}
      onCancel={onClose}
      onOk={form.submit}
      okText="Create Invoice"
      cancelText="Cancel"
      destroyOnClose
      afterClose={() => form.resetFields()}
      confirmLoading={isPending}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16}>
          {isError && (
            <Col span={24}>
              <Alert
                type="error"
                closable
                //@ts-ignore
                message={error?.message}
                //@ts-ignore
                description={error?.body?.message || error?.message}
              />
            </Col>
          )}
          <Col span={12}>
            <Form.Item label="Invoice Number" name="invoiceNumber">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Customer"
              name="customer"
              rules={[{ required: true, message: "Please select a customer" }]}
            >
              <CustomerSelect isForm={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Due Date"
              name="invoiceDueDate"
              rules={[{ required: true, message: "Please select a due date" }]}
              initialValue={dayjs()}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Issue Date"
              name="invoiceDate"
              rules={[
                { required: true, message: "Please select an issue date" },
              ]}
              initialValue={dayjs()}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Billing Address" name="billingAddress">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Status"
              name="invoiceStatus"
              initialValue={InvoiceStatus.DRAFT}
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <Select
                options={Object.keys(InvoiceStatus).map((key) => ({
                  label: key,
                  value: key,
                  key: key + "invoice-select-status",
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Account"
              name="account"
              rules={[{ required: true }]}
            >
              <PaymentAccountSelect isForm={true} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Amount Paid"
              name="amountPaid"
              rules={[
                {
                  // max: calculateTotal(),
                  message:
                    "Amount paid cannot exceed the total amount" +
                    " " +
                    calculateTotal(),
                  validator(rule, value) {
                    if (value > 0) {
                      const status = form.getFieldValue("invoiceStatus");
                      if (
                        status !== InvoiceStatus.PAID &&
                        status !== InvoiceStatus.PARTIAL
                      ) {
                        return Promise.reject(
                          new Error("Amount paid cannot be greater than zero")
                        );
                      }

                      if (value > calculateTotal()) {
                        return Promise.reject(
                          new Error(
                            "Amount paid cannot exceed the total amount"
                          )
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                },
                {
                  type: "number",
                  message: "Please enter a valid amount",
                },
              ]}
            >
              <InputNumber min={0.01} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Tax" name="tax">
              <TaxSelect
                isForm={true}
                selectProps={{ onSelect: () => handleTaxChange(), onClear: handleTaxChange }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Note" name="note">
              <TextArea />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Flex
              justify="flex-end"
              align="flex-end"
              style={{ height: "100%", width: "100%" }}
            >
              <Form.Item label="" name="options">
                <Checkbox.Group
                  options={[{ label: "Reduce inventory", value: true }]}
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Table
              className="non-hight-auto"
              columns={columns}
              dataSource={items}
              pagination={false}
              rowKey="key"
              scroll={{ x: "max-content" }}
              size="small"
            />
          </Col>
        </Row>
        <Space.Compact style={{ width: "100%" }}>
          <Button
            type="dashed"
            onClick={addItem}
            style={{ width: "100%", marginTop: 16 }}
          >
            Add Item
          </Button>
        </Space.Compact>
        <div style={{ marginTop: 16, textAlign: "right" }}>
          <h3>Subtotal: {calculateSubtotal().toFixed(2)}</h3>
          <h3>Total Discount: {calculateTotalDiscount().toFixed(2)}</h3>
          <h3>Total Tax: {calculateTotalTax().toFixed(2)}</h3>
          <h3>Total: {calculateTotal().toFixed(2)}</h3>
        </div>
      </Form>
    </Modal>
  );
};

export default InvoiceCreation;
