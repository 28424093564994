import React from "react";
import { Table, Descriptions, Divider, Typography, Row, Col, Flex, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { motion } from "framer-motion";
import { IDeliverNote, IDeliverNoteItem } from "api/requests";
import dayjs from "dayjs";
const { Title } = Typography;

interface DeliveryNoteProps {
  deliveryNote?: IDeliverNote;
}

const DeliveryNoteView: React.FC<DeliveryNoteProps> = ({ deliveryNote }) => {
  const columns: ColumnsType<IDeliverNoteItem> = [
    {
      title: "Product",
      dataIndex: ["product", "productName"],
      key: "product",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Location",
      dataIndex: ["location", "locationName"],
      key: "location",
    },
    {
      title: "Transaction Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date: Date) => dayjs(date).format("DD/MM/YYYY HH:mm"),
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      {!deliveryNote ? (
        <Flex justify="center" align="center" style={{ height: "100%", minHeight: "40vh", flexDirection: "column" }}>
          <Typography.Title level={5}>No delivery note</Typography.Title>
          {/* TODO: button to create a new delivery note */}
          <Button type="primary">Create</Button>
        </Flex>
      ) : (
        <>
          <Row style={{ margin: "2rem 0 0 0" }}>
            <Col xs={24}>
              <Descriptions
                column={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
                layout="vertical"
                style={{ marginBottom: "20px" }}
              >
                <Descriptions.Item label="Description">
                  {deliveryNote.deliverNoteDescription}
                </Descriptions.Item>
                <Descriptions.Item label="Number">
                  {deliveryNote.deliveryNoteNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Entry Date">
                  {new Date(deliveryNote.entryDate).toLocaleDateString()}
                </Descriptions.Item>
                <Descriptions.Item label="Created By">
                  {/*@ts-ignore*/}
                  {deliveryNote.createdBy?.userFullName}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Divider orientation="left">Items</Divider>
          <Row justify="center">
            <Col xs={24}>
              <Table
                columns={columns}
                dataSource={deliveryNote.deliveryNoteItems}
                rowKey="idDeliveryNoteItem"
                pagination={false}
                bordered
              />
            </Col>
          </Row>
        </>
      )}
    </motion.div>
  );
};

export default DeliveryNoteView;
