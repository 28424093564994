import Cookie from "js-cookie";

const cookies: any = {};

cookies.get = (key: string) => {
  return Cookie.get(key);
};

cookies.set = (key: string, value: string, opts: Cookie.CookieAttributes = { expires: 3600 }) => {
  return Cookie.set(key, value, opts);
};

cookies.remove = (key: string) => {
  return Cookie.remove(key);
};

export default cookies;