import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import {
  useAccountingServiceGetPaymentAccounts,
  useAccountingServiceGetPaymentAccountsKey,
} from "api/queries";
import queryString from "qs";
import { useEffect, useState } from "react";

interface IPaymentAccountSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: any[];
  selectProps?: Partial<SelectProps>;
}

const PaymentAccountSelectWithForm: React.FC<IPaymentAccountSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetPaymentAccounts({
      filters,
      pagination: "",
      sort: "",
    });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a payment account"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.accountName,
            value: _v.idAccount,
            key: _v.idAccount + "account-select-payment-account",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const PaymentAccountSelectNoForm: React.FC<IPaymentAccountSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetPaymentAccounts({
      filters,
      pagination: "",
      sort: "",
    });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            accountName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a payment account"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.accountName,
            value: _v.idAccount,
            key: _v.idAccount + "account-select-payment-account",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const PaymentAccountSelect: React.FC<IPaymentAccountSelectProps> = ({
  isForm,
  ...rest
}) => {
  if (isForm) {
    return <PaymentAccountSelectWithForm {...rest} />;
  }
  return <PaymentAccountSelectNoForm {...rest} />;
};

export default PaymentAccountSelect;
