import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import {
  useCustomerServiceGetCustomers,
  useCustomerServiceGetCustomersKey,
} from "api/queries";
import { ICustomer } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface ICustomerSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: ICustomer[];
  selectProps?: Partial<SelectProps>;
}

const CustomerSelectWithForm: React.FC<ICustomerSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, refetch } = useCustomerServiceGetCustomers({
    filters,
    pagination: "",
    sort: "",
  });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            customerName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a customer"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.customerName,
            value: _v.idCustomer,
            key: _v.idCustomer + "customer-select-customer",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const CustomerSelectNoForm: React.FC<ICustomerSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, refetch } = useCustomerServiceGetCustomers({
    filters,
    pagination: "",
    sort: "",
  });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            customerName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a customer"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.customerName,
            value: _v.idCustomer,
            key: _v.idCustomer + "customer-select-customer",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

export default function CustomerSelect(
  props: ICustomerSelectProps
) {
  if (props.isForm) {
    return <CustomerSelectWithForm {...props} />;
  }
  return <CustomerSelectNoForm {...props} />;
} 