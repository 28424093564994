import { QueryClient } from "@tanstack/react-query";
import { Col, Form, Input, Modal, notification, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceGetModelsKey,
  useInventoryServiceUpdateModel,
} from "api/queries";
import { useEffect } from "react";

interface IEditProductModelModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
  modelData: any;
}

const EditProductModelModal: React.FC<IEditProductModelModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  modelData,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useInventoryServiceUpdateModel({
    onError: (error: any) => {
      notification.error({
        description: error?.body?.message || error?.response?.data?.message || error?.message,
        type: "error",
        message: "Failed to edit model",
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetModelsKey],
        refetchType: "all",
      });
      form.resetFields();
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idModel: modelData.idModel,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }

    if (isOpen) {
      form.setFieldsValue({
        modelName: modelData?.modelName,
        modelDescription: modelData?.modelDescription,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Edit Model"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-model-form"
      >
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="modelName"
              label="Model Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="modelDescription" label="Description">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditProductModelModal;
