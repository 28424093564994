import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Space, Typography } from "antd";
import { FC } from "react";
import { PurchaseOrderItem } from "./PurchaseOrderItem";
import { IProductItem } from "api/requests";

interface IItemSelectionProps {
  selectedItems: any[];
  setSelectedItems: any;
}
export const ItemSelection: FC<IItemSelectionProps> = ({ selectedItems, setSelectedItems }) => {

  const addItem = () => {
    //if any of the item is not selected then don't add it
    if (selectedItems.some((i) => !i.idProduct)) {
      return;
    }
    setSelectedItems([
      ...selectedItems,
      { idProduct: null, quantity: 1, unitPrice: 0, productName: "", totalPrice: 0 },
    ]);
  };

  const removeItem = (index: number) => {
    setSelectedItems(selectedItems.filter((_, i) => i !== index));
  };

  const handleItemChange = (index: number, value: any, itemDetails: IProductItem) => {
    //if the item is already selected then don't change it
    if (selectedItems.filter((i) => i.idProduct === value).length > 0) {
      return;
    }
    const item = selectedItems[index];
    item.idProduct = value;
    item.productName = itemDetails?.productName;
    item.unitPrice = itemDetails?.productCost || 0;
    item.totalPrice = item.quantity * item.unitPrice;
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  const handleQuantityChange = (index: number, value: number) => {
    const item = selectedItems[index];
    item.quantity = value;
    item.totalPrice = value * item.unitPrice;
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  const handleUnitPriceChange = (index: number, value: number) => {
    const item = selectedItems[index];
    item.unitPrice = value;
    item.totalPrice = value * item.quantity;
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  const handleClearItem = (index: number) => {
    const item = selectedItems[index];
    item.idProduct = null;
    item.quantity = 1;
    item.unitPrice = 0;
    item.productName = "";
    item.totalPrice = 0;
    item.value = null;
    const newItems = [...selectedItems];
    newItems[index] = item;
    setSelectedItems(newItems);
  };

  return (
    <Flex vertical style={{ width: "100%" }}>
      <Space.Compact block>
        <Typography style={{ width: "40%" }}>Item</Typography>
        <Typography style={{ width: "20%" }}>Quantity</Typography>
        <Typography style={{ width: "20%" }}>Unit Price</Typography>
        <Typography style={{ width: "20%" }}>Total Price</Typography>
      </Space.Compact>

      {selectedItems.map((item, index) => (
        <PurchaseOrderItem
          key={`item-in-purchase-order-${index}`}
          item={item}
          onChange={handleItemChange}
          index={index}
          onQuantityChange={handleQuantityChange}
          onClearItem={handleClearItem}
          onRemoveItem={removeItem}
          onUnitPriceChange={handleUnitPriceChange}
        />
      ))}

      <Divider orientation="center">
        <Button icon={<PlusOutlined />} onClick={addItem}>
          Add Item
        </Button>
      </Divider>
    </Flex>
  );
};
