import { Select, SelectProps } from "antd";
import { useInventoryServiceGetLocations } from "api/queries";
import { ILocationItem, IProductItem } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface ILocationSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: ILocationItem[];
  selectProps?: Partial<SelectProps>;
}

const LocationSelectWithForm: React.FC<ILocationSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, refetch } = useInventoryServiceGetLocations({
    filters,
    pagination: "",
    sort: "",
  });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            locationName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a product"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      //@ts-ignore
      onClear={() => onChange(null)}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.locationName,
            value: _v.idLocation,
            key: _v.idLocation + "product-select-product",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const LocationSelectNoForm: React.FC<ILocationSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, refetch } = useInventoryServiceGetLocations({
    filters,
    pagination: "",
    sort: "",
  });

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            productName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a product"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.locationName,
            value: _v.idLocation,
            key: _v.idLocation + "product-select-product",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

export default function LocationSelect(
  props: ILocationSelectProps
) {
  if (props.isForm) {
    return <LocationSelectWithForm {...props} />;
  }
  return <LocationSelectNoForm {...props} />;
}