import { QueryClient } from "@tanstack/react-query";
import { Alert, Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceCreateLocation,
  useInventoryServiceGetLocationsKey,
} from "api/queries";
import { useEffect } from "react";
import { LocationType } from "utils/enums";

export const CreateLocationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}> = ({ isOpen, onClose, queryClient }) => {
  const [form] = Form.useForm();

  const { mutate, isPending, isError, error } =
    useInventoryServiceCreateLocation({
      onError: (error: any) => {
        console.dir(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useInventoryServiceGetLocationsKey],
        });
        onClose();
      },
    });

  const handleSubmit = (values: any) => {
    mutate({ requestBody: { ...values, isActive: true } });
  };

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen]);

  return (
    <Modal
      title="Create Location"
      open={isOpen}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={isPending}
      maskClosable={false}
      destroyOnClose
      cancelButtonProps={{ disabled: isPending }}
      okButtonProps={{ disabled: isPending }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        // wrapperCol={{ span: 24 }}
        initialValues={{ modifier: "public" }}
        onFinish={handleSubmit}
        disabled={isPending}
      >
        <Row gutter={16}>
          {isError && (
            <Col xs={24}>
              <Alert
                type="error"
                closable
                message={error?.message}
                description={error?.body?.message || error?.message}
              />
            </Col>
          )}
          <Col xs={12}>
            <Form.Item
              name="locationName"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input the location name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="locationType"
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Please input the location type!",
                },
              ]}
            >
              <Select
                options={Object.keys(LocationType).map((key) => ({
                  value: key,
                  label: key,
                  key: key + "location-select-location-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="locationDescription"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please input the location description!",
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
