import {
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Table,
  TableColumnType,
  Typography,
} from "antd";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { ServiceCategoryItem } from "api/requests";
import {
  useInventoryServiceGetServiceCategory,
  useInventoryServiceGetServiceCategoryKey,
} from "api/queries";
import queryString from "query-string";
import { CreateServiceCategoryModal } from "./CreateServiceCategoryModal";
import { useQueryClient } from "@tanstack/react-query";

export const ServiceCategoryListing = () => {
  const [createServiceCategoryOpen, setCreateServiceCategoryOpen] =
    useState(false);
  const [editServiceCategoryOpen, setEditServiceCategoryOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data, isLoading, isPending, isFetching } =
    useInventoryServiceGetServiceCategory({
      filters: "",
      pagination: queryString.stringify({ offset: (page - 1) * limit, limit }),
      sort: "",
    });

  const queryClient = useQueryClient();

  const columns: TableColumnType<ServiceCategoryItem>[] = [
    {
      key: "serviceCategoryName",
      title: "Name",
      dataIndex: "serviceCategoryName",
      width: 200,
    },
    {
      key: "serviceCategoryDescription",
      title: "Description",
      dataIndex: "serviceCategoryDescription",
      width: 200,
    },
    {
      key: "isActive",
      title: "Is Active",
      dataIndex: "isActive",
      render: (value) => (value ? "Yes" : "No"),
      width: 200,
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetServiceCategoryKey],
    });
  }, [page, limit]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Service Category
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setCreateServiceCategoryOpen(true)}
            >
              Create
            </Button>
          </Flex>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            key={"product-category-list"}
            rowKey={(record) => record.idServiceCategory}
            scroll={{ x: "max-content" }}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              onChange: (page) => setPage(page),
              onShowSizeChange: (_, limit) => setLimit(limit),
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </Col>
      </Row>

      <CreateServiceCategoryModal
        isOpen={createServiceCategoryOpen}
        onClose={() => setCreateServiceCategoryOpen(false)}
      />
    </motion.div>
  );
};
