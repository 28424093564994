import React from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { LayoutHeader } from "./LayoutHeader";
import LayoutSlider from "./LayoutSideNav";
import PageWrapper from "./PageWrapper";
import { AnimatePresence } from "framer-motion";

const { useToken } = theme;

const App: React.FC = () => {
  const { token } = useToken();
  const [sidnavCollapsed, setSidenavCollapsed] = React.useState(false);
  return (
    <Layout
      style={{ height: "100%", borderBottom: "0.5px solid rgba(0,0,0, .5)" }}
    >
      <LayoutHeader handleCollapse={() => setSidenavCollapsed(!sidnavCollapsed)} />
      <Layout style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center"
      }}>
        <LayoutSlider collapsed={sidnavCollapsed} />
        <AnimatePresence>
          <PageWrapper />
        </AnimatePresence>
      </Layout>
    </Layout>
  );
};

export default App;
