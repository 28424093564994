import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  Input,
  List,
  message,
  Modal,
  notification,
  Space,
} from "antd";
import {
  useSettingsServiceCreateProductVariantType,
  useSettingsServiceGetProductVariantTypesKey,
  useSettingsServiceUpdateProductVariantType,
} from "api/queries";
import { useState, useEffect } from "react";

const EditVariantTypeModal = ({
  isOpen,
  onClose,
  initialValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: any;
}) => {
  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const { mutate, isPending } = useSettingsServiceUpdateProductVariantType();

  useEffect(() => {
    if (initialValues) {
      form.setFieldValue("name", initialValues?.name);
    }
  }, [initialValues]);

  // Submit handler
  const handleSubmit = async (values: any) => {
    mutate(
      {
        requestBody: values,
        idProductVariantType: initialValues?.idProductVariantType,
      },
      {
        onSuccess: () => {
          message.success("Variant Type updated successfully.");
          onClose();
          queryClient.invalidateQueries({
            queryKey: [useSettingsServiceGetProductVariantTypesKey],
          });
          form.resetFields();
        },
        onError: (error: any) => {
          notification.error({
            message: "Error updating variant type.",
            description: error?.body?.message || error?.response?.data?.message || error?.message,
          });
        },
      }
    );
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title="Manage Variant"
      open={isOpen}
      onCancel={handleClose}
      onOk={form.submit}
      destroyOnClose
      confirmLoading={isPending}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Variant Type Name"
          rules={[{ required: true, message: "Please enter a name" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditVariantTypeModal;
