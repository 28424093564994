import { Alert, Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import {
  useAccountingServiceCreateBankAccount,
  useAccountingServiceGetBankAccountsKey,
  useAccountingServiceGetCurrencies,
} from "api/queries";
import CurrencySelect from "components/common/CurrencySelect";
import queryString from "qs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BankAccountHolderType, BankAccountType } from "utils/enums";

interface ICreateBankAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
}

const CreateBankAccountModal: React.FC<ICreateBankAccountModalProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [form] = Form.useForm();
  const [exchangeRate, setExchangeRate] = useState<any>(null);
  const [exchangeRateView, setExchangeRateView] = useState<string>("");

  const { mutate, isPending, isError, error } =
    useAccountingServiceCreateBankAccount({
      onError: (error: any) => {
        console.dir(error);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useAccountingServiceGetBankAccountsKey],
          refetchType: "all",
        });
        onClose();
      },
    });

  const handleSubmit = (values: any) => {
    mutate({ requestBody: values });
  };

  const company = useSelector((state: any) => state.company);

  const { data: currencyData } = useAccountingServiceGetCurrencies({
    filters: "",
    sort: "",
    pagination: queryString.stringify({ limit: 100, offset: 0 }),
  });

  const handleExchangeRateView = () => {
    const currencyRateID = form.getFieldValue("currency");
    const amount = form.getFieldValue("openingBalance") || 0;

    if (!currencyRateID) {
      setExchangeRateView("");
      return;
    }

    const currency = currencyData?.items?.find(
      (i) => i.idCurrency === currencyRateID
    );

    if (!amount) {
      setExchangeRateView("");
    }

    //@ts-ignore
    setExchangeRateView(
      `${amount} ${currency?.currencySymbol} / ${
        //@ts-ignore
        currency?.exchangeRate?.rate
        //@ts-ignore
      } = ${(amount / currency?.exchangeRate?.rate).toFixed(2)} ${
        company.currencySymbol
      }`
    );
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Create Bank Account"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
      // width={{ x}}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="create-bank-account-form"
      >
        <Row gutter={16}>
          {isError && (
            <Alert
              message={error?.body?.message || error?.message || error}
              type="error"
              showIcon
            />
          )}
          <Col xs={12}>
            <Form.Item
              name="bankName"
              label="Bank Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountName"
              label="Account Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountNumber"
              label="Account Number"
              rules={[{ required: false }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountType"
              label="Type"
              rules={[{ required: true }]}
            >
              <Select
                options={Object.keys(BankAccountType).map((key) => ({
                  label: key,
                  value: key,
                  key: key + "bank-account-select-bank-account-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountHolderName"
              label="Holder Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountHolderType"
              label="Holder Type"
              rules={[{ required: true }]}
            >
              <Select
                options={Object.keys(BankAccountHolderType).map((key) => ({
                  label: key,
                  value: key,
                  key: key + "bank-account-select-bank-account-holder-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="isActive"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { label: "Active", value: true },
                  { label: "Inactive", value: false },
                ]}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="currency"
              label="Currency"
              rules={[{ required: true }]}
            >
              <CurrencySelect
                isForm={true}
                selectProps={{
                  onSelect: () => handleExchangeRateView(),
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="openingBalance"
              label={`Opening Balance`}
              initialValue={0}
              extra={<span>{exchangeRateView}</span>}
            >
              <InputNumber
                min={0.0}
                style={{ width: "100%" }}
                onInput={() => handleExchangeRateView()}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateBankAccountModal;
