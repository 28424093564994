import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "USD",
  currencyCode: "USD",
  currencySymbol: "$",
  companyName: "Sun Dairy",
  companyAddress: "Company Address",
  companyPhone: "Company Phone",
  companyEmail: "Company Email",
  companyLogo: "Company Logo",
  companyWebsite: "Company Website",
  companyVat: "Company Vat",
};

export const companySlice = createSlice({
  initialState,
  reducers: {
    setCompany: (state, action) => {
      state.currency = action.payload.currency;
      state.currencyCode = action.payload.currencyCode;
      state.currencySymbol = action.payload.currencySymbol;
      state.companyName = action.payload.companyName;
      state.companyAddress = action.payload.companyAddress;
      state.companyPhone = action.payload.companyPhone;
      state.companyEmail = action.payload.companyEmail;
      state.companyLogo = action.payload.companyLogo;
      state.companyWebsite = action.payload.companyWebsite;
      state.companyVat = action.payload.companyVat;
    },
  },
  name: "company",
});

export const { setCompany } = companySlice.actions;

export default companySlice.reducer;
