export function getInitials(input: string): string {
  // Split the string by spaces and filter out any empty strings
  const words = input
    .trim()
    .split(" ")
    .filter((word) => word.length > 0);

  if (words.length === 0) {
    return ""; // Handle empty input or input with only spaces
  } else if (words.length === 1) {
    const firstWord = words[0];
    // If only one word and it's a single letter, return that letter + '0'
    return firstWord.length === 1
      ? firstWord.toUpperCase() + "0"
      : firstWord.slice(0, 2).toUpperCase();
  } else {
    // Otherwise, return the first letter of the first two words
    return (words[0][0] + words[1][0]).toUpperCase();
  }
}
