import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Form,
  Input,
  List,
  message,
  Modal,
  notification,
  Space,
  Typography,
} from "antd";
import {
  useSettingsServiceCreateProductVariantValue,
  useSettingsServiceDeleteProductVariantValue,
  useSettingsServiceUpdateProductVariantValue, // Add mutation for updating variant value
  useSettingsServiceGetProductVariantTypesKey,
} from "api/queries";
import { useState, useEffect } from "react";

const ViewVariantTypeModal = ({
  isOpen,
  onClose,
  initialValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: any;
}) => {
  const [variantTypeValues, setVariantTypeValues] = useState<any[]>(
    initialValues?.variantTypeValues || []
  );
  const [inputValue, setInputValue] = useState("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState("");
  const [deletingID, setDeletingID] = useState<number | null>(null);

  const queryClient = useQueryClient();

  const { mutate: createVariantValue, isPending: isCreating } =
    useSettingsServiceCreateProductVariantValue();

  const { mutate: deleteVariantValue, isPending: isDeleting } =
    useSettingsServiceDeleteProductVariantValue();

  const { mutate: updateVariantValue, isPending: isUpdating } =
    useSettingsServiceUpdateProductVariantValue();

  const handleAdd = async () => {
    if (!inputValue.trim()) {
      message.error("Please enter a name before adding.");
      return;
    }

    if (variantTypeValues.some((val) => val.name === inputValue.trim())) {
      message.error("Name must be unique.");
      return;
    }

    await createVariantValue(
      {
        requestBody: {
          productVariantType: initialValues?.idProductVariantType,
          name: inputValue.trim(),
        },
      },
      {
        onSuccess: () => {
          setVariantTypeValues([
            ...variantTypeValues,
            { idProductVariant: null, name: inputValue.trim() },
          ]);
          setInputValue("");
          queryClient.invalidateQueries({
            queryKey: [useSettingsServiceGetProductVariantTypesKey],
          });
        },
        onError: (error: any) => {
          notification.error({
            message: "Error creating variant type.",
            description: error?.body?.message || error?.response?.data?.message || error?.message,
          });
        },
      }
    );
  };

  // Handle Remove variant value
  const handleRemove = async (index: number) => {
    const itemID = variantTypeValues[index].idProductVariant;
    setDeletingID(itemID);
    await deleteVariantValue(
      {
        idProductVariantValue: itemID,
      },
      {
        onSuccess: () => {
          const newVariantTypeValues = [...variantTypeValues];
          newVariantTypeValues.splice(index, 1);
          setVariantTypeValues(newVariantTypeValues);
          queryClient.invalidateQueries({
            queryKey: [useSettingsServiceGetProductVariantTypesKey],
          });
          setDeletingID(null);
        },
        onError: (error: any) => {
          notification.error({
            message: "Error deleting variant type.",
            description: error?.body?.message || error?.response?.data?.message || error?.message,
          });
          setDeletingID(null);
        },
      }
    );
  };

  // Handle Edit variant value
  const handleEdit = (index: number) => {
    setEditingIndex(index);
    setEditingValue(variantTypeValues[index].name);
  };

  // Handle Save edited variant value
  const handleSaveEdit = async (index: number) => {
    const item = variantTypeValues[index];
    if (!editingValue.trim()) {
      message.error("Please enter a valid name.");
      return;
    }

    await updateVariantValue(
      {
        idProductVariantValue: item.idProductVariant,
        requestBody: { name: editingValue.trim() },
      },
      {
        onSuccess: () => {
          const newVariantTypeValues = [...variantTypeValues];
          newVariantTypeValues[index].name = editingValue.trim();
          setVariantTypeValues(newVariantTypeValues);
          setEditingIndex(null);
          queryClient.invalidateQueries({
            queryKey: [useSettingsServiceGetProductVariantTypesKey],
          });
        },
        onError: (error: any) => {
          notification.error({
            message: "Error updating variant value.",
            description: error?.body?.message || error?.response?.data?.message || error?.message,
          });
        },
      }
    );
  };

  const handleClose = () => {
    setVariantTypeValues(initialValues?.variantTypeValues || []);
    setEditingIndex(null);
    onClose();
  };

  useEffect(() => {
    if (initialValues) {
      setVariantTypeValues(initialValues?.productVariantValues || []);
    } else {
      setVariantTypeValues([]);
    }
  }, [initialValues]);

  return (
    <Modal
      title={initialValues?.name}
      open={isOpen}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ disabled: isDeleting || isCreating || isUpdating }}
      okButtonProps={{ style: { display: "none" } }}
      cancelText="Close"
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter variant value"
        />
        <Button
          onClick={handleAdd}
          disabled={!inputValue.trim()}
          type="primary"
          loading={isCreating}
        >
          Add Variant Value
        </Button>
      </Space>

      <List
        dataSource={variantTypeValues}
        renderItem={(item, idx) => (
          <List.Item
            actions={[
              editingIndex === idx ? (
                <Button type="primary" onClick={() => handleSaveEdit(idx)} loading={isUpdating}>
                  Save
                </Button>
              ) : (
                <Button onClick={() => handleEdit(idx)}>Edit</Button>
              ),
              <Button
                danger
                onClick={() => handleRemove(idx)}
                loading={isDeleting && deletingID === item.idProductVariant}
              >
                Remove
              </Button>,
            ]}
          >
            {editingIndex === idx ? (
              <Input
                value={editingValue}
                onChange={(e) => setEditingValue(e.target.value)}
              />
            ) : (
              item.name
            )}
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ViewVariantTypeModal;
