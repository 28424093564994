import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import {
  useAccountingServiceGetTaxes,
  useAccountingServiceGetTaxesKey,
} from "api/queries";
import { ITaxItem } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface ITaxSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: ITaxItem[];
  selectProps?: Partial<SelectProps>;
}

const TaxSelectWithForm: React.FC<ITaxSelectProps> = ({
  isForm,
  onChange,
  value,
  items,
  selectProps,
}) => {
  const [filters, setFilters] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetTaxes(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useAccountingServiceGetTaxesKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value ? queryString.stringify({ taxName: { $ilike: `${value}%` } }) : ""
    );
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useAccountingServiceGetTaxesKey],
    });
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a tax"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      {...selectProps}
      onSearch={handleFilter}
      onClear={() => {
        //@ts-ignore
        onChange(null);
        selectProps?.onClear?.();
      }}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.taxName,
            value: _v.idTax,
            key: _v.idTax + "tax-id-select-tax",
          };
        }) ?? []
      }
    />
  );
};

const TaxSelectNoForm: React.FC<ITaxSelectProps> = ({
  isForm,
  onChange,
  value,
  items,
  selectProps,
}) => {
  const [filters, setFilters] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching } = useAccountingServiceGetTaxes(
    {
      filters,
      pagination: "",
      sort: "",
    },
    [useAccountingServiceGetTaxesKey]
  );

  const handleFilter = (value: string) => {
    setFilters(
      value ? queryString.stringify({ taxName: { $ilike: `${value}%` } }) : ""
    );
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useAccountingServiceGetTaxesKey],
    });
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        const vend = data?.items?.find((i) => i.idTax === v);
        //@ts-ignore
        onChange(vend);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Search tax"
      notFoundContent={null}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.taxName,
            value: _v.idTax,
            key: _v.idTax + "tax-id-select-tax",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...selectProps}
    />
  );
};

const TaxSelect: React.FC<ITaxSelectProps> = ({ isForm, ...rest }) => {
  if (isForm) {
    return <TaxSelectWithForm {...rest} />;
  }
  return <TaxSelectNoForm {...rest} />;
};

export default TaxSelect;
