import { PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  AutoComplete,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useAccountingServiceCreateTransaction,
  useAccountingServiceGetAccounts,
} from "api/queries";
import queryString from "query-string";
import { FC, useState } from "react";
import { TransactionType } from "utils/enums";

export const CreateTransactionModal: FC<any> = ({ isOpen, onClose }) => {
  const [selectedDebit, setSelectedDebit] = useState(null);
  const [selectedCredit, setSelectedCredit] = useState(null);

  const qc = useQueryClient();

  const { data: accountsRes } = useAccountingServiceGetAccounts({
    sort: "",
    pagination: queryString.stringify({ limit: 100, offset: 0 }),
    filters: "",
  });

  const { mutate, isPending, error } = useAccountingServiceCreateTransaction(
    {}
  );

  const handleSubmit = (values: any) => {
    values.amount = +values.amount;
    values.transactionDate = new Date();
    mutate(
      {
        requestBody: values,
      },
      {
        onError: (error: any) => {
          notification.error({
            message: "Error",
            description: error?.body?.message || error?.response?.data?.message || error?.message,
          });
        },
        onSuccess: (data) => {
          qc.invalidateQueries({ queryKey: ["TRANSACTION_LIST"] });
          onClose(true);
        },
      }
    );
  };

  const [formRef] = Form.useForm();

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      okText="Create"
      okButtonProps={{
        icon: <PlusOutlined />,
        loading: isPending,
      }}
      cancelButtonProps={{ disabled: isPending }}
      onOk={formRef.submit}
    >
      <Row>
        <h1>Create Transaction</h1>
      </Row>
      <Row>
        <Col xs={24}>
          <Form form={formRef} onFinish={handleSubmit} layout="vertical">
            <Row gutter={30}>
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Debit"
                  name="debitAccount"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Debit account must be selected",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    options={accountsRes?.items.map((i) => ({
                      value: i.idAccount,
                      label: i.accountName,
                      key: i.idAccount + "account-select-debit",
                      options: [
                        {
                          value: i.idAccount,
                          label: i.accountName,
                          key: i.idAccount + i.accountName,
                        },
                        ...i.childAccounts.map((c) => ({
                          value: c.idAccount,
                          label: c.accountName,
                          key: c.idAccount + "child" + i.accountName,
                        })),
                      ],
                    }))}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Credit"
                  name="creditAccount"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Credit account must be selected",
                    },
                  ]}
                >
                  <Select
                    // style={{ width: 200 }}
                    // labelRender={() => "null"}
                    // value={"sldkfj"}
                    showSearch={true}
                    options={accountsRes?.items.map((i) => ({
                      value: i.idAccount,
                      label: i.accountName,
                      key: i.idAccount + "account-select-credit",
                      options: [
                        {
                          value: i.idAccount,
                          label: i.accountName,
                          key: i.idAccount + i.accountName,
                        },
                        ...i.childAccounts.map((c) => ({
                          value: c.idAccount,
                          label: c.accountName,
                          key: c.idAccount + "child" + i.accountName,
                        })),
                      ],
                    }))}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Transaction type"
                  name="transactionType"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Transaction type must be selected",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    options={Object.keys(TransactionType).map((i) => {
                      return {
                        value: i,
                        label: i,
                        key: i,
                      };
                    })}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  hasFeedback
                  label="Amount"
                  name="amount"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Amount must be entered",
                    },
                    {
                      min: 0.01,
                      message: "Amount must be at least 0.01",
                    },
                  ]}
                >
                  <Input name="amount" type="number" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Description"
                  name="transactionDescription"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: "Description must be entered",
                    },
                    {
                      min: 0.01,
                      message: "Description must be at least 0.01",
                    },
                  ]}
                >
                  <TextArea name="description" rows={2} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  hasFeedback
                  label="Note"
                  name="note"
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: false,
                      message: "note must be entered",
                    },
                  ]}
                >
                  <TextArea name="note" rows={2} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};
