//code for dashboard screen
import React from "react";
import { motion } from "framer-motion";
import { Button, Col, Flex, Row, Segmented, Select, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { DashboardOverView } from "./Overview";

export default function Dashboard() {
  const [currentView, setCurrentView] = React.useState("overview");

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row gutter={[16, 16]} justify={"space-between"}>
        <Col xs={24}>
          <Flex justify="space-between" align="center">
            <Segmented
              onChange={(v) => setCurrentView(v)}
              value={currentView}
              options={[
                { label: "Overview", value: "overview" },
                { label: "Inventory", value: "inventory" },
                { label: "Accounting", value: "accounting" },
                { label: "Contacts", value: "contacts" },
              ]}
            />

            <span>
              <Select
                defaultValue={"month"}
                options={[
                  { label: "Today", value: "today" },
                  { label: "This Week", value: "week" },
                  { label: "This Month", value: "month" },
                  { label: "This Year", value: "year" },
                ]}
              />

              <Button
                type="primary"
                icon={<DownloadOutlined />}
                style={{ marginLeft: "1rem" }}
              >
                Export
              </Button>
            </span>
          </Flex>
        </Col>

        {currentView === "overview" ? (
          <DashboardOverView />
          // <div></div>
        ) : (
          <Flex
            justify="center"
            align="center"
            style={{ height: "100%", width: "100%" }}
          >
            <Typography.Title level={5}>Under construction</Typography.Title>
          </Flex>
        )}
      </Row>

      {currentView !== "overview" ? (
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0 }}
          src="/under-construction.gif"
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            margin: "-24px",
            borderBottomLeftRadius: 12,
          }}
        />
      ) : null}
    </motion.div>
  );
}
