import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Segmented,
  Spin,
  theme,
  Tree,
  Typography,
} from "antd";
import {
  useAccountingServiceGetAccounts,
  useAccountingServiceGetAccountsKey,
} from "api/queries";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { AccountType } from "utils/enums";

const { useToken } = theme;

export default function ChartOfAccounts() {
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [segmentedAccounts, setSegmentedAccounts] = useState<any>({});
  const [selectedSegment, setSelectedSegment] = useState("assets");

  const { data, isLoading } = useAccountingServiceGetAccounts(
    {
      filters: queryString.stringify(filters),
      sort: "",
      pagination: queryString.stringify({ limit, offset }),
    },
    [useAccountingServiceGetAccountsKey]
  );

  const { token } = useToken();

  useEffect(() => {
    const b: any = {};

    data?.items.forEach((i) => {
      //for assets
      if (i.accountType === AccountType.ASSET) {
        if (b?.[AccountType.ASSET]) {
          if (b[AccountType.ASSET][i.accountGroup]) {
            b[AccountType.ASSET][i.accountGroup].push(i);
          } else {
            b[AccountType.ASSET][i.accountGroup] = [];
            b[AccountType.ASSET][i.accountGroup].push(i);
          }
        } else {
          b[AccountType.ASSET] = {};
          b[AccountType.ASSET][i.accountGroup] = [];
          b[AccountType.ASSET][i.accountGroup].push(i);
        }
      }

      // //for liabilities
      if (i.accountType === AccountType.LIABILITY) {
        if (b?.[AccountType.LIABILITY]) {
          if (b[AccountType.LIABILITY][i.accountGroup]) {
            b[AccountType.LIABILITY][i.accountGroup].push(i);
          } else {
            b[AccountType.LIABILITY][i.accountGroup] = [];
            b[AccountType.LIABILITY][i.accountGroup].push(i);
          }
        } else {
          b[AccountType.LIABILITY] = {};
          b[AccountType.LIABILITY][i.accountGroup] = [];
          b[AccountType.LIABILITY][i.accountGroup].push(i);
        }
      }

      // //for equity
      if (i.accountType === AccountType.EQUITY) {
        if (b?.[AccountType.EQUITY]) {
          if (b[AccountType.EQUITY][i.accountGroup]) {
            b[AccountType.EQUITY][i.accountGroup].push(i);
          } else {
            b[AccountType.EQUITY][i.accountGroup] = [];
            b[AccountType.EQUITY][i.accountGroup].push(i);
          }
        } else {
          b[AccountType.EQUITY] = {};
          b[AccountType.EQUITY][i.accountGroup] = [];
          b[AccountType.EQUITY][i.accountGroup].push(i);
        }
      }

      //for revenue
      if (i.accountType === AccountType.REVENUE) {
        if (b?.[AccountType.REVENUE]) {
          if (b[AccountType.REVENUE][i.accountGroup]) {
            b[AccountType.REVENUE][i.accountGroup].push(i);
          } else {
            b[AccountType.REVENUE][i.accountGroup] = [];
            b[AccountType.REVENUE][i.accountGroup].push(i);
          }
        } else {
          b[AccountType.REVENUE] = {};
          b[AccountType.REVENUE][i.accountGroup] = [];
          b[AccountType.REVENUE][i.accountGroup].push(i);
        }
      }

      //for expenses
      if (i.accountType === AccountType.EXPENSE) {
        if (b?.[AccountType.EXPENSE]) {
          if (b[AccountType.EXPENSE][i.accountGroup]) {
            b[AccountType.EXPENSE][i.accountGroup].push(i);
          } else {
            b[AccountType.EXPENSE][i.accountGroup] = [];
            b[AccountType.EXPENSE][i.accountGroup].push(i);
          }
        } else {
          b[AccountType.EXPENSE] = {};
          b[AccountType.EXPENSE][i.accountGroup] = [];
          b[AccountType.EXPENSE][i.accountGroup].push(i);
        }
      }
    });

    setSegmentedAccounts(b);
  }, [data]);

  const getAccountsList = (segmentedAccounts: any, accT: AccountType) => {
    return (
      <Row>
        <>
          {Object.keys(segmentedAccounts?.[accT] || {}).map((key: string) => (
            <>
              <Col
                xs={24}
                style={{
                  backgroundColor: token.colorPrimaryBg,
                  padding: ".5rem .5rem",
                  borderBottom: "1px solid",
                  borderBottomColor: token.colorPrimaryBorder,
                }}
                key={`item-key-${key}`}
              >
                <Flex justify="space-between" align="center">
                  <Typography.Title
                    level={5}
                    style={{ textTransform: "capitalize", margin: 0 }}
                  >
                    {key.replaceAll("_", " ").toLowerCase()}
                  </Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    0$
                  </Typography.Title>
                </Flex>
              </Col>

              {segmentedAccounts?.[accT]?.[key]?.map((group: any) => (
                <>
                  <Col
                    xs={24}
                    style={{
                      padding: ".5rem .8rem",
                      borderBottom: "1px solid",
                      borderBottomColor: token.colorPrimaryBorder,
                    }}
                    key={`item-group-key-${group.accountName}`}
                  >
                    <Flex justify="space-between" align="center">
                      <Typography.Title
                        level={5}
                        style={{
                          textTransform: "capitalize",
                          margin: 0,
                        }}
                      >
                        {group.accountName}
                      </Typography.Title>
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        {group?.calculatedBalance || 0}$
                      </Typography.Title>
                    </Flex>
                  </Col>
                </>
              ))}
            </>
          ))}
        </>
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Typography.Title level={2} color="primary">
            Chart of accounts {data?.total ? `(${data?.total})` : ""}
          </Typography.Title>
        </Col>

        <Col xs={12}>
          <Flex justify="end" align="center">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              // onClick={handleCreateAccountModalOpen}
            >
              Create
            </Button>
          </Flex>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <Divider
            // style={{ opacity: 0.4 }}
            children={[
              <Segmented
                options={[
                  {
                    label: "Assets",
                    value: "assets",
                  },
                  {
                    label: "Liabilities",
                    value: "liabilities",
                  },
                  {
                    label: "Equity",
                    value: "equity",
                  },
                  {
                    label: "Revenue",
                    value: "revenue",
                  },
                  {
                    label: "Expenses",
                    value: "expenses",
                  },
                ]}
                value={selectedSegment}
                onChange={(value) => setSelectedSegment(value)}
              />,
            ]}
          />
        </Col>
      </Row>

      {/* <Row style={{ height: "80%", boxSizing: "border-box", overflow: "auto" }}> */}
      {getAccountsList(
        segmentedAccounts,
        selectedSegment === "assets"
          ? AccountType.ASSET
          : selectedSegment === "liabilities"
          ? AccountType.LIABILITY
          : selectedSegment === "equity"
          ? AccountType.EQUITY
          : selectedSegment === "revenue"
          ? AccountType.REVENUE
          : AccountType.EXPENSE
      )}
      {/* </Row> */}

      <Row style={{ height: "80%", boxSizing: "border-box" }}>
        <Col
          xs={24}
          style={{ height: "100%", display: isLoading ? "grid" : "none", margin: "1rem 0" }}
        >
          <Flex justify="center" align="center" style={{ height: "100%" }}>
            <Spin />
          </Flex>
        </Col>
      </Row>
    </>
  );
}
