import { useQueryClient } from "@tanstack/react-query";
import { Select, SelectProps } from "antd";
import {
  useAccountingServiceGetCurrencies,
  useAccountingServiceGetCurrenciesKey,
} from "api/queries";
import { ICurrency } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface ICurrencySelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: ICurrency[];
  selectProps?: Partial<SelectProps>;
}

const CurrencySelectWithForm: React.FC<ICurrencySelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetCurrencies(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useAccountingServiceGetCurrenciesKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            currencyName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      showSearch
      value={value}
      onChange={onChange}
      notFoundContent={false}
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a currency"
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.currencyName,
            value: _v.idCurrency,
            key: _v.idCurrency + "currency-select-currency",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const CurrencySelectNoForm: React.FC<ICurrencySelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetCurrencies(
      {
        filters,
        pagination: "",
        sort: "",
      },
      [useAccountingServiceGetCurrenciesKey]
    );

  const handleFilter = (value: string) => {
    setFilters(
      value
        ? queryString.stringify({
            currencyName: {
              $ilike: `${value}%`,
            },
          })
        : ""
    );
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || isRefetching}
      placeholder="Select a currency"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        data?.items?.map((_v) => {
          return {
            label: _v.currencyName,
            value: _v.idCurrency,
            key: _v.idCurrency + "currency-select-currency",
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const CurrencySelect: React.FC<ICurrencySelectProps> = ({
  isForm,
  ...rest
}) => {
  if (isForm) {
    return <CurrencySelectWithForm {...rest} />;
  }
  return <CurrencySelectNoForm {...rest} />;
};

export default CurrencySelect;
