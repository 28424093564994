import { Col, Form, Input, message, Modal, Row, Select } from "antd";
import {
  useAccountingServiceCreateTax,
  useAccountingServiceGetTaxesKey,
} from "api/queries";
import { $TaxType } from "api/requests";
import React from "react";

interface ICreateTaxModelProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
}

const CreateTaxModel: React.FC<ICreateTaxModelProps> = ({
  isOpen,
  onClose,
  queryClient,
}) => {
  const [form] = Form.useForm();
  const { mutate, isPending } = useAccountingServiceCreateTax({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetTaxesKey],
      });
      form.resetFields();
      onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to create tax");
    },
  });

  const handleSubmit = (values: any) => {
    mutate({ requestBody: values });
  };

  return (
    <Modal
      title="Create Tax"
      open={isOpen}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={form.submit}
      confirmLoading={isPending}
      okText="Create"
      cancelText="Cancel"
      destroyOnClose
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        autoComplete="off"
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        name="create-tax-form"
      >
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item name="taxName" rules={[{ required: true }]}>
              <Input placeholder="Tax Name" />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="taxRate"
              rules={[{ required: true }, { min: 0.01 }]}
            >
              <Input placeholder="Tax Rate" type="number" min="0" step="0.01" />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item name="taxType" rules={[{ required: true }]}>
              <Select
                placeholder="Tax Type"
                options={$TaxType.enum.map((abc) => ({
                  label: abc,
                  value: abc,
                  key: abc + "tax-select-tax-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item name="taxDescription">
              <Input placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateTaxModel;
