import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography,
} from "antd";
import CreateInvoiceModal from "./CreateInvoice";
import queryString from "query-string";
import { PlusCircleOutlined } from "@ant-design/icons";
import InvoiceStatusComponent, {
  InvoiceStatus,
} from "components/common/InvoiceStatus";
import { TableActions } from "components/common";
import dayjs from "dayjs";
import { useSalesServiceGetInvoices } from "api/queries";
import { useNavigate } from "react-router-dom";
import useFilters, { FilterType } from "hooks/UseFilter";

export default function InvoiceListing() {
  const [createInvoiceModalOpen, setCreateInvoiceModalOpen] = useState(false);
  const [editInvoiceModalOpen, setEditInvoiceModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortQuery, setSortQuery] = useState("");

  const queryClient = useQueryClient();
  const nav = useNavigate();

  const filtersDef: any[] = [
    {
      key: "invoiceNumber",
      label: "Invoice Number",
      type: FilterType.Text,
    },
    {
      key: "invoiceStatus",
      label: "Invoice Status",
      type: FilterType.MultiSelect,
      options: [
        { label: "Draft", value: "DRAFT" },
        { label: "Sent", value: "SENT" },
        { label: "Paid", value: "PAID" },
        { label: "Partial", value: "PARTIAL" },
        { label: "Unpaid", value: "UNPAID" },
        { label: "Over Due", value: "OVER_DUE" },
      ],
    },
    //date range for issue date
    {
      key: "invoiceDate",
      label: "Issue Date",
      type: FilterType.DateRange,
    },
    {
      key: "invoiceDueDate",
      label: "Due Date",
      type: FilterType.DateRange,
    },
    {
      key: "billingAddress",
      label: "Billing Address",
      type: FilterType.Text,
    }
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isPending, isFetching, refetch } =
    useSalesServiceGetInvoices({
      filters: filtersQuery,
      pagination: queryString.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      sort: sortQuery,
    });

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: "auto",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: "auto",
      render: (customer: any) => customer?.customerName || "-",
    },
    {
      title: "Due Date",
      dataIndex: "invoiceDueDate",
      key: "invoiceDueDate",
      width: "auto",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Issue Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: "auto",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Billing Address",
      dataIndex: "billingAddress",
      key: "billingAddress",
      width: "auto",
    },
    {
      title: "Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      width: "auto",
      render: (status: string) => (
        <InvoiceStatusComponent status={status as InvoiceStatus} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_r: any, record: any) => (
        <TableActions
          onView={() => nav(`/sales/invoices/${record.idInvoice}`)}
          onEdit={() => handleOpenEditInvoiceModal(record)}
          onDeletePopConfirm={() => handleDeleteInvoice(record.idInvoice)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This invoice will be deleted permanently"
          // onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  const handleOpenEditInvoiceModal = (invoice: any) => {
    setEditInvoiceModalOpen(true);
    setSelectedInvoice(invoice);
  };

  const handleCloseEditInvoiceModal = () => {
    setEditInvoiceModalOpen(false);
  };

  const handleDeleteInvoice = async (id: string) => {
    // await deleteInvoiceMutation({
    //   idInvoice: id,
    // });
    refetch();
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    if (pagination) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter && Object.keys(sorter).length > 0) {
      const s: any = {};
      s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
      setSortQuery(s);
    }
  };

  useEffect(() => {
    refetch();
  }, [sortQuery, limit, page]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Typography.Title>Invoices</Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Space>
            {FilterButton}
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={() => setCreateInvoiceModalOpen(true)}
            >
              Add new
            </Button>
          </Space>
        </Col>

        <Col xs={24}>
          {Filters}
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            loading={false}
            columns={columns}
            dataSource={data?.items}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
            rowKey={(item) => item.idInvoice}
          />
        </Col>
      </Row>

      <CreateInvoiceModal
        isOpen={createInvoiceModalOpen}
        onClose={() => setCreateInvoiceModalOpen(false)}
        queryClient={queryClient}
      />
    </motion.div>
  );
}
