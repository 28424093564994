import { Col, Descriptions, Row } from "antd";
import { IVendor } from "api/requests";
import { motion } from "framer-motion";

export interface IVendorDetailsComponentProps {
  data: IVendor;
}

export const VendorDetailsComponent: React.FC<IVendorDetailsComponentProps> = ({
  data,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24}>
          <Descriptions
            style={{ width: "100%", margin: "2rem 0" }}
            items={[
              {
                label: "Vendor Name",
                children: data?.vendorName,
              },
              {
                label: "Description",
                children: data?.description,
              },
              {
                label: "Country",
                children: data?.country,
              },
              {
                label: "Address",
                children: data?.address,
              },
              {
                label: "Phone",
                children: data?.phone,
              },
              {
                label: "Email",
                children: data?.email,
              },
              {
                label: "Website",
                children: data?.website,
              },
            ]}
          />
        </Col>
      </Row>
    </motion.div>
  );
};
