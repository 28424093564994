import { Form, Input, Button, Checkbox, Card, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// import { useAuthServiceLogin } from "api/queries";
import useAuth from "hooks/UseAuth";
import { useNavigate } from "react-router-dom";
import { useAuthServiceLogin } from "api/queries";

const LoginForm = () => {
  const auth = useAuth();
  const nav = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate: login, isPending } = useAuthServiceLogin({
    onError: (error: any) => {
      messageApi.open({
        type: "error",
        content: error?.message || error,
      });
    },
    onSuccess: (data) => {
      auth.login({
        token: data.token || "",
        user: data?.user?.userFullName || "",
        role: data?.user?.role,
        company: "", //data.user.companyName,
      });
      nav("/");
    },
  });

  const onFinish = (values: any) => {
    const { username, password } = values;
    login({ requestBody: { username, password } });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {contextHolder}
      <Card>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ maxWidth: "300px" }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="" style={{ float: "right" }}>
              Forgot password
            </a>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
              loading={isPending}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
