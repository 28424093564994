import { Button, Col, message, notification, Row, Typography } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  useUsersServiceDeleteRole,
  useUsersServiceGetRoles,
  useUsersServiceGetRolesKey,
} from "api/queries";
import qs from "qs";
import Table, { ColumnType } from "antd/es/table";
import { TableActions } from "components/common";
import { useEffect, useState } from "react";
import CreateRoleModal from "./CreateRoleModal";

const RolesSettingsPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [createRoleModalOpen, setCreateRoleModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const nav = useNavigate();

  const { data, isLoading, error, isError, refetch } = useUsersServiceGetRoles({
    filters: "",
    pagination: qs.stringify({
      offset: (page - 1) * limit,
      limit,
    }),
    sort: sortQuery,
  });

  const { mutate: deleteRoleMutation, isPending: isDeletePending } =
    useUsersServiceDeleteRole({
      onSuccess: () => {
        message.success("Role deleted successfully");
        refetch();
      },
      onError: (error: any) => {
        notification.error({
          description: error?.body?.message || error?.response?.data?.message || error?.message,
          type: "error",
          message: "Failed to delete role",
        });
      },
    });

  const columns: ColumnType<any>[] = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Role Description",
      dataIndex: "roleDescription",
      key: "roleDescription",
      width: "auto",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_text, record) => (
        <TableActions
          onEdit={() => nav(`/settings/roles/${record.idRole}`)}
          onDeletePopConfirm={() => handleDeleteRole(record.idRole)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This role will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  const handleDeleteRole = async (id: string) => {
    await deleteRoleMutation({
      roleId: +id,
    });
    refetch();
  };

  const handleOpenCreateRoleModal = () => {
    setCreateRoleModalOpen(true);
  };

  const handleCloseCreateRoleModal = () => {
    setCreateRoleModalOpen(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useUsersServiceGetRolesKey],
    });
  }, [page, limit, sortQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: "0px" }}>
            Roles
          </Typography.Title>

          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={handleOpenCreateRoleModal}
          >
            Add new
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: "12px 0" }}></Row>
      <Row>
        <Col xs={24}>
          <Table
            style={{ height: "400px" }}
            scroll={{ x: "max-content" }}
            columns={columns}
            key={"user-list"}
            //@ts-ignore
            dataSource={data?.items}
            rowKey={(item) => item.idRole}
            loading={isLoading}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateRoleModal
        isOpen={createRoleModalOpen}
        onClose={handleCloseCreateRoleModal}
        queryClient={queryClient}
      />
    </motion.div>
  );
};

export default RolesSettingsPage;
