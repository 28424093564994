import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Card,
  Space,
  message,
  Alert,
} from "antd";
import {
  useAccountingServiceGetCurrencies,
  useAccountingServiceGetPaymentAccounts,
  useInventoryServiceGetPurchaseOrder,
  useInventoryServiceGetPurchaseOrderKey,
  useInventoryServiceRecordPurchaseOrderPayment,
} from "api/queries";
import { IPurchaseOrder } from "api/requests";
import CurrencySelect from "components/common/CurrencySelect";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AccountSubGroup } from "utils/enums";

const { Text } = Typography;

interface CreatePaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  data: IPurchaseOrder;
}

export default function CreatePaymentModal(props: CreatePaymentModalProps) {
  const [selectedCurrency, setSelectedCurrency] = useState<any | null>(null);
  const [exchangeRateAmount, setExchangeRateAmount] = useState<number>(1);
  const [exchangeRateView, setExchangeRateView] = useState<string>("");
  const [form] = Form.useForm();
  const [amountToPay, setAmountToPay] = useState<number>(0);

  const company = useSelector((state: any) => state.company);

  const { data: paymentAccounts, isLoading: isPaymentAccountsLoading } =
    useAccountingServiceGetPaymentAccounts({
      filters: "",
      pagination: "",
      sort: "",
    });

  const { data: currencies, isLoading: isCurrenciesLoading } =
    useAccountingServiceGetCurrencies({
      filters: "",
      pagination: "",
      sort: "",
    });

  const {
    mutate: createPaymentMutation,
    isPending,
    error,
    isError,
  } = useInventoryServiceRecordPurchaseOrderPayment({
    onSuccess: () => {
      props.queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetPurchaseOrderKey],
      });
      form.resetFields();
      props.onClose();
    },
    onError: (err: any) => {
      message.error(err?.message || err || "Failed to create payment");
    },
  });

  const totalAmountPaid = props.data?.totalPaymentsAmount || 0;
  const totalPurchaseOrderPrice = props.data?.totalAmount || 0;
  const amountRemaining = totalPurchaseOrderPrice - totalAmountPaid;

  const handlePaymentAccountChange = () => {
    const selectAccountId = form.getFieldValue("paymentAccount");

    const sAccount = paymentAccounts?.items?.find(
      (i) => i.idAccount === selectAccountId
    );

    if (sAccount) {
      if (
        sAccount.currency?.exchangeRate?.rate &&
        sAccount.currency?.exchangeRate?.rate > 1
      ) {
        setExchangeRateAmount(sAccount.currency?.exchangeRate.rate);
        form.setFieldsValue({
          exchangeRateAmount: sAccount.currency?.exchangeRate?.rate,
        });
      } else {
        setExchangeRateAmount(1);
        form.setFieldsValue({
          exchangeRateAmount: 1,
        });
      }
    }

    if (sAccount?.currency) {
      setSelectedCurrency(sAccount.currency);
      form.setFieldsValue({
        currency: sAccount.currency.idCurrency,
      });
    }
  };

  useEffect(() => {
    handleExchangeRateViewChange();
  }, [exchangeRateAmount, selectedCurrency]);

  const handleExchangeRateViewChange = () => {
    if (exchangeRateAmount === 1) {
      setExchangeRateView("");
    }

    const amount = form.getFieldValue("amount");

    setExchangeRateView(
      `${amount} ${selectedCurrency?.currencySymbol} / ${exchangeRateAmount} ${
        company?.currencySymbol
      } = ${(amount / exchangeRateAmount).toFixed(2)} ${
        company?.currencySymbol
      }`
    );
  };

  const handleCurrencyChange = () => {
    const selectCurrencyId = form.getFieldValue("currency");

    const sCurrency = currencies?.items?.find(
      (i) => i.idCurrency === selectCurrencyId
    );

    if (sCurrency) {
      setSelectedCurrency(sCurrency);
      form.setFieldsValue({
        currency: sCurrency.idCurrency,
      });
    }

    // Change exchange rate
    const exchangeRate = sCurrency?.exchangeRate;

    if (exchangeRate) {
      setExchangeRateAmount(exchangeRate.rate);
      form.setFieldsValue({
        exchangeRateAmount: exchangeRate.rate,
      });
    } else {
      setExchangeRateAmount(1);
      form.setFieldsValue({
        exchangeRateAmount: 1,
      });
    }
  };

  const handleAmountChange = (value: number | string) => {
    setAmountToPay(Number(value));
    handleExchangeRateViewChange();
  };

  const handleSubmit = async (values: any) => {
    // Validate amount before submission
    if (amountToPay / exchangeRateAmount <= 0) {
      message.error("Payment amount must be greater than zero.");
      return;
    }

    if (amountToPay / exchangeRateAmount > amountRemaining) {
      message.error("Payment amount cannot exceed the remaining amount.");
      return;
    }

    createPaymentMutation({
      purchaseOrderId: props.data?.idPurchaseOrder,
      requestBody: {
        purchaseOrderId: props.data?.idPurchaseOrder,
        paymentAccount: form.getFieldValue("paymentAccount"),
        paymentAmount: values.amount,
        currency: form.getFieldValue("currency"),
        exchangeRateAmount: exchangeRateAmount,
        paymentDate: dayjs(form.getFieldValue("paymentDate")).format(),
        referenceNumber: form.getFieldValue("referenceNumber"),
        note: form.getFieldValue("note"),
      },
    });
  };

  return (
    <Modal
      open={props.isOpen}
      onCancel={props.onClose}
      title="Create Payment"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col xs={24}>
            {isError && (
              <Alert
                message={error?.body?.message || error?.message || error}
                type="error"
                showIcon
              />
            )}
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="paymentAccount"
              label="Payment Account"
              rules={[{ required: true }]}
            >
              <Select
                loading={isPaymentAccountsLoading}
                options={paymentAccounts?.items?.map((item) => ({
                  label: item.accountName,
                  value: item.idAccount,
                }))}
                filterOption={false}
                notFoundContent={false}
                allowClear
                onSelect={() => handlePaymentAccountChange()}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="currency"
              label="Currency"
              rules={[{ required: true }]}
            >
              <CurrencySelect
                isForm={true}
                selectProps={{ onSelect: handleCurrencyChange }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="exchangeRateAmount"
              label="Exchange Rate Amount"
              rules={[{ required: true }]}
              extra={exchangeRateView}
            >
              <InputNumber
                min={0.01}
                style={{ width: "100%" }}
                value={exchangeRateAmount}
                onChange={handleExchangeRateViewChange}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true }]}
              initialValue={1}
            >
              <InputNumber
                min={0.01}
                style={{ width: "100%" }}
                onInput={handleAmountChange}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              name="paymentDate"
              label="Payment Date"
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item name="referenceNumber" label="Reference Number">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="note" label="Note">
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Card
              title="Payment Summary"
              bordered={false}
              style={{ marginTop: 16, fontSize: "14px" }}
              bodyStyle={{ padding: "8px", backgroundColor: "#f9f9f9" }}
            >
              <Row gutter={16}>
                <Col xs={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text strong>Total Amount Paid:</Text>
                    <Text>
                      {totalAmountPaid * exchangeRateAmount}
                      {selectedCurrency?.currencySymbol ||
                        company?.currencySymbol}
                    </Text>
                  </Space>
                </Col>
                <Col xs={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text strong>Amount To Be Paid Now:</Text>
                    <Text>
                      {amountToPay}
                      {selectedCurrency?.currencySymbol ||
                        company?.currencySymbol}
                    </Text>
                  </Space>
                </Col>
                <Col xs={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text strong>Total Purchase Order Price:</Text>
                    <Text>
                      {totalPurchaseOrderPrice * exchangeRateAmount}
                      {selectedCurrency?.currencySymbol ||
                        company?.currencySymbol}
                    </Text>
                  </Space>
                </Col>

                <Col xs={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text strong>Amount Remaining:</Text>
                    <Text>
                      {amountRemaining * exchangeRateAmount}
                      {selectedCurrency?.currencySymbol ||
                        company?.currencySymbol}
                    </Text>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
