import React from "react";
import { ConfigProvider, ThemeConfig } from "antd";

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const selectedTheme: "Purple" | "Green" | "Orange" = "Orange";

  const getThemeColors = () => {
    switch (selectedTheme) {
      //@ts-ignore
      case "Purple":
        return {
          colorPrimaryBg: "#f9f0ff",
          colorPrimaryBgHover: "#efdbff",
          colorPrimaryBorder: "#d3adf7",
          colorPrimaryBorderHover: "#b37feb",
          colorPrimaryHover: "#9254de",
          colorPrimary: "#722ed1",
          colorPrimaryActive: "#531dab",
          colorPrimaryTextHover: "#9254de",
          colorPrimaryText: "#722ed1",
          colorPrimaryTextActive: "#531dab",
        };
      case "Orange":
        return {
          colorPrimaryBg: "#fff7e6",
          colorPrimaryBgHover: "#ffe7ba",
          colorPrimaryBorder: "#ffd591",
          colorPrimaryBorderHover: "#ffc069",
          colorPrimaryHover: "#ffa940",
          colorPrimary: "#fa8c16",
          colorPrimaryActive: "#d46b08",
          colorPrimaryTextHover: "#ffa940",
          colorPrimaryText: "#fa8c16",
          colorPrimaryTextActive: "#d46b08",
          // colorBgLayout: "#ffffff",

        };
      default:
        return {
          colorBgLayout: "#ffffff",
          colorBgContainer: "#ffffff",
          colorBgElevated: "#ffffff",
          colorBgSpotlight: "rgba(0, 0, 0, 0.85)",
          colorBgMask: "rgba(0, 0, 0, 0.45)",
        };
    }
  };
  const theme: ThemeConfig = {
    token: {
      // colorBgBase: "#F5F8FE",
      fontSizeSM: 12,
      fontSize: 14,
      fontSizeLG: 18,
      fontSizeXL: 24,
      fontSizeIcon: 14,
      fontSizeHeading1: 24,
      fontSizeHeading2: 20,
      fontSizeHeading3: 18,
      fontSizeHeading4: 16,
      fontSizeHeading5: 14,
      ...getThemeColors(),
    },
    // inherit: true,
    components: {
      Typography: {
        fontSizeHeading1: 24,
        fontSizeHeading2: 20,
        fontSizeHeading3: 18,
        fontSizeHeading4: 16,
        fontSizeHeading5: 14,
        colorTextHeading: "#000000",
      },
      Table: {},
    },
  };
  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
};
