import { Button, Col, message, Row, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateUserDialog from "./CreateUser";
import { motion } from "framer-motion";
import {
  useUsersServiceDeleteUser,
  useUsersServiceGetUsers,
  useUsersServiceGetUsersKey,
} from "api/queries";
import { TableActions } from "components/common";
// import qs from "qs";
// import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import useFilters, { FilterTemplate, FilterType } from "hooks/UseFilter";
import QueryString from "qs";
import EditUserDialog from "./EditUser";

export default function UserListing() {
  const [createNewDialogOpen, setCreateNewDialogOpen] = useState(true);
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [sortQuery, setSortQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);
  const queryClient = useQueryClient();
  // const nav = useNavigate();

  const filtersDef: any[] = [
    {
      key: "userName",
      label: "User Name",
      type: FilterType.Text,
    },
    {
      key: "userFullName",
      label: "User Full Name",
      type: FilterType.Text,
    },
    {
      key: "role",
      label: "Role",
      type: FilterType.template,
      template: FilterTemplate.RoleSelect,
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const { data, isLoading, isRefetching, refetch } = useUsersServiceGetUsers({
    filters: filtersQuery,
    pagination: QueryString.stringify({
      offset: (page - 1) * limit,
      limit,
    }),
    sort: sortQuery,
  });

  const { mutate: deleteUserMutation, isPending: isDeletePending } =
    useUsersServiceDeleteUser({
      onSuccess: () => {
        message.success("User deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleOpenEditUserModal = (user: any) => {
    setEditUserDialogOpen(true);
    setSelectedUser(user);
  };

  const handleCloseEditUserModal = () => {
    setEditUserDialogOpen(false);
  };

  const handleDeleteUser = async (id: string) => {
    await deleteUserMutation({
      userId: +id,
    });
    refetch();
  };

  const columns: ColumnsType = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: "auto",
      sorter: true,
    },
    {
      title: "User Full Name",
      dataIndex: "userFullName",
      key: "userFullName",
      width: "auto",
      sorter: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        //TODO: remove after role is implemented
        //@ts-ignore
        return record.role?.roleName;
      },
      width: "auto",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_r, record) => (
        <TableActions
          onEdit={() => handleOpenEditUserModal(record)}
          onDeletePopConfirm={() => handleDeleteUser(record.idUser)}
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This user will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
      width: "auto",
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const handleCreateUserModalClose = (success?: boolean) => {
    setCreateUserModalOpen(false);
  };

  const handleCreateUserModalOpen = () => {
    setCreateUserModalOpen(true);
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useUsersServiceGetUsersKey],
    });
  }, [page, limit, sortQuery, filtersQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: 0 }}>
            Users
          </Typography.Title>
          <Space>
            {FilterButton}
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={handleCreateUserModalOpen}
            >
              Add new
            </Button>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Table
            style={{ height: "400px" }}
            scroll={{ x: "max-content" }}
            columns={columns}
            key={"user-list"}
            //@ts-ignore
            dataSource={data?.items}
            loading={isLoading || isRefetching}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateUserDialog
        isOpen={createUserModalOpen}
        onClose={handleCreateUserModalClose}
        queryClient={queryClient}
      />
      <EditUserDialog
        isOpen={editUserDialogOpen}
        onClose={handleCloseEditUserModal}
        queryClient={queryClient}
        defaultValues={selectedUser}
      />
    </motion.div>
  );
}
