import { Col, Row, Table } from "antd";
import { useState, useEffect } from "react";
import { useInventoryServiceGetPurchaseOrderPayments } from "api/queries";
import queryString from "qs";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

interface IPurchaseOrderPaymentProps {
  purchaseOrderId?: number;
}
export default function PurchaseOrderPayment({ purchaseOrderId }: IPurchaseOrderPaymentProps) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [filtersQuery, setFiltersQuery] = useState("");

  const company = useSelector((state: any) => state.company);

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetPurchaseOrderPayments({
      filters: "",
      pagination: queryString.stringify({ limit: 100, offset: 0 }),
      sort: "",
      purchaseOrderId : purchaseOrderId || 0,
    });

  const columns: any[] = [
    {
      title: "Payment Number",
      dataIndex: "paymentNumber",
      key: "paymentNumber",
      width: "auto",
      sorter: true,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: "auto",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
      sorter: true,
    },
    {
      title: "Payment Account",
      dataIndex: "paymentAccount",
      key: "paymentAccount",
      width: "auto",
      render: (account: any) => account?.accountName,
      sorter: true,
    },
    {
      title: "Payment Amount",
      dataIndex: "amount",
      key: "amount",
      width: "auto",
      render: (amount: number) => `${company.currencySymbol}${amount.toFixed(2)}`,
      sorter: true,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      width: "auto",
      render: (type: string) => type,
      sorter: true,
    },
    {
      title: "Reference Number",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      width: "auto",
      sorter: true,
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: "auto",
      sorter: true,
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   width: "auto",
    //   render: (text: string, record: any) => (
    //     <TableActions
    //       onEdit={() => console.log("edit")}
    //       onDeletePopConfirm={() => console.log("delete")}
    //       onDeletePopConfirmMessage="Are you sure?"
    //       onDeletePopConfirmDescription="This payment will be deleted permanently"
    //       onDeleteLoading={isDeletePending}
    //     />
    //   ),
    // },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [sortQuery, limit, page]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ margin: "2rem 0" }}
    >
      <Row>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isRefetching}
            // @ts-ignore
            rowKey={(item) => item.idPurchaseOrderPayment}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["2", "5", "10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </motion.div>
  );
}
