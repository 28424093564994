import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Typography,
  Row,
  Col,
  Divider,
  message,
  notification,
  Space,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import CreateProductModal from "./CreateProductModal";
import { TableActions } from "components/common";
import {
  useInventoryServiceDeleteProduct,
  useInventoryServiceGetProducts,
  useInventoryServiceGetProductsKey,
} from "api/queries";
import queryString from "qs";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnType } from "antd/es/table";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import EditProductModal from "./EditProductModal";
import useFilters, {
  FilterTemplate,
  FilterType,
  IFilterItem,
} from "hooks/UseFilter";
import ability, { Can } from "utils/abilit";

const { Title } = Typography;

const ProductListing: React.FC = () => {
  const [createProductOpen, setCreateProductOpen] = React.useState(false);
  const [editProductOpen, setEditProductOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortQuery, setSortQuery] = useState("");

  const nav = useNavigate();

  const queryClient = useQueryClient();

  const filtersDef: IFilterItem[] = [
    {
      key: "productName",
      label: "Name",
      type: FilterType.Text,
    },
    {
      key: "productCategory",
      label: "Category",
      type: FilterType.template,
      template: FilterTemplate.ProductCategorySelect,
    },
    // {
    //   key: "brand",
    //   label: "Brand",
    //   type: FilterType.template,
    //   template: FilterTemplate.BrandSelect,
    // },
    {
      key: "productSku",
      label: "SKU",
      type: FilterType.Text,
    },
    {
      key: "productBarcode",
      label: "Barcode",
      type: FilterType.Text,
    },
    {
      key: "productEan",
      label: "EAN",
      type: FilterType.Text,
    },
    {
      key: "productUpc",
      label: "UPC",
      type: FilterType.Text,
    },
    {
      key: "productGtin",
      label: "GTIN",
      type: FilterType.Text,
    },
    {
      key: "productWeight",
      label: "Weight",
      type: FilterType.Text,
    },
    {
      key: "productWeightUnit",
      label: "Weight Unit",
      type: FilterType.Text,
    },
    {
      key: "productDimensionUnit",
      label: "Dimension Unit",
      type: FilterType.Text,
    },
    {
      key: "productLength",
      label: "Length",
      type: FilterType.Text,
    },
    {
      key: "productWidth",
      label: "Width",
      type: FilterType.Text,
    },
    {
      key: "productHeight",
      label: "Height",
      type: FilterType.Text,
    },
    {
      key: "productDimension",
      label: "Dimension",
      type: FilterType.Text,
    },
    {
      key: "productImage",
      label: "Image",
      type: FilterType.Text,
    },
    {
      key: "productVideo",
      label: "Video",
      type: FilterType.Text,
    },
    {
      key: "productPrice",
      label: "Price",
      type: FilterType.Text,
    },
    {
      key: "productCost",
      label: "Cost",
      type: FilterType.Text,
    },
    {
      key: "productLowStockAlert",
      label: "Low Stock Alert",
      type: FilterType.Text,
    },
    {
      key: "productIdealStock",
      label: "Ideal Stock",
      type: FilterType.Text,
    },
    {
      key: "productDescription",
      label: "Description",
      type: FilterType.Text,
    },
    {
      key: "productVariabts",
      label: "Variabts",
      type: FilterType.template,
      template: FilterTemplate.ProductVariantSelect,
    },
  ];

  const { filtersQuery, FilterButton, Filters } = useFilters(filtersDef);

  const {
    data: products,
    isLoading,
    isRefetching,
    isPending,
    refetch,
  } = useInventoryServiceGetProducts(
    {
      pagination: queryString.stringify({ limit, offset }),
      filters: queryString.stringify(filtersQuery),
      sort: sortQuery,
    },
    [useInventoryServiceGetProductsKey]
  );

  const { mutate: deleteProductMutation, isPending: isDeletePending } =
    useInventoryServiceDeleteProduct({
      onSuccess: () => {
        message.success("Product deleted successfully");
        refetch();
      },
      onError: (error: any) => {
        notification.error({
          description: error?.body?.message || error?.response?.data?.message || error?.message,
          type: "error",
          message: "Failed to delete product",
        });
      },
    });

  const hadnleOpenCreateProduct = () => {
    setCreateProductOpen(true);
  };

  const hadnleCloseCreateProduct = async (reload?: boolean) => {
    if (reload) {
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetProductsKey],
      });
    }
    setCreateProductOpen(false);
  };

  const handleOpenEditProductModal = (product: any) => {
    setEditProductOpen(true);
    setSelectedProduct(product);
  };

  const handleCloseEditProductModal = () => {
    setEditProductOpen(false);
    setSelectedProduct(null);
  };

  const handleDeleteProduct = async (id: string) => {
    await deleteProductMutation({
      idProduct: +id,
    });
    refetch();
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setOffset((pagination.current || 1) - 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const columns: ColumnType<any>[] = [
    {
      title: "Name",
      dataIndex: "productName",
      key: "productName",
      sorter: true,
      width: "auto",
    },
    {
      title: "SKU",
      dataIndex: "productSku",
      key: "productSku",
      sorter: true,
      width: "auto",
    },
    {
      title: "Category",
      dataIndex: "productCategory",
      key: "productCategory",
      render: (category: any) => category?.productCategoryName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (brand: any) => brand?.brandName || "-",
      sorter: true,
      width: "auto",
    },
    {
      title: "Price",
      dataIndex: "productPrice",
      key: "productPrice",
      render: (price: number) => `$${(price || 0).toFixed(2)}`,
      sorter: true,
      width: "auto",
    },
    {
      title: "Cost",
      dataIndex: "productCost",
      key: "productCost",
      render: (cost: number) => `$${(cost || 0).toFixed(2)}`,
      sorter: true,
      width: "auto",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_v, _r) => (
        <TableActions
          onView={() => nav(`/inventory/products/${_r.idProduct}`)}
          onEdit={
            ability.can("UPDATE", "PRODUCTS")
              ? () => handleOpenEditProductModal(_r)
              : undefined
          }
          onDeletePopConfirm={
            ability.can("DELETE", "PRODUCTS")
              ? () => handleDeleteProduct(_r.idProduct.toString())
              : undefined
          }
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This product will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [useInventoryServiceGetProductsKey],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, sortQuery, filtersQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ position: "relative", height: "100%" }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={2} style={{ margin: 0 }}>
            Products
          </Title>

          <Space>
            {FilterButton}

            <Can I="CREATE" a="PRODUCTS">
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={hadnleOpenCreateProduct}
              >
                Add New
              </Button>
            </Can>
          </Space>
        </Col>

        <Col xs={24}>{Filters}</Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={products?.items || []}
        loading={isLoading || isPending || isRefetching}
        rowKey={(item) => item.idProduct}
        key={"product-list"}
        pagination={{
          total: products?.total || 0,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
        }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
      />

      <CreateProductModal
        isOpen={createProductOpen}
        onClose={hadnleCloseCreateProduct}
      />

      <EditProductModal
        isOpen={editProductOpen}
        onClose={handleCloseEditProductModal}
        queryClient={queryClient}
        defaultValues={selectedProduct}
      />
    </motion.div>
  );
};

export default ProductListing;
