import {
  Button,
  Col,
  Divider,
  Empty,
  Flex,
  Row,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { CreateTransactionModal } from "./CreateTransactionModel";
import {
  useAccountingServiceGetAccountsKey,
  useAccountingServiceGetTransactions,
  useAccountingServiceGetTransactionsKey,
} from "api/queries";
import { useEffect, useState } from "react";
import * as qs from "qs";
import { endOfDay, startOfDay } from "date-fns";
import { TransactionItem } from "api/requests";
import { ColumnsType } from "antd/es/table";
import DataTable, { TableColumn } from "react-data-table-component";
import { useQueryClient } from "@tanstack/react-query";
import queryString from "query-string";
import { TableActions } from "components/common";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
export default function TransactionListing() {
  const [createTransactionModalOpen, setCreateTransactionModalOpen] =
    useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [filters, setFilters] = useState({
    $and: {
      createDate: [startOfDay(new Date()), endOfDay(new Date())],
    },
  });

  const company = useSelector((state: any) => state.company);

  const RenderAmount = (amount: number, record: any) => {
    return `${(amount * record.exchangeRateAmount)} ${record?.currency?.currencySymbol || company.currencySymbol}`
  };

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetTransactions({
      filters: qs.stringify(filters),
      pagination: queryString.stringify({
        limit,
        offset: (offset - 1) * limit,
      }),
      sort: sortQuery,
    });

  const columns = [
    {
      title: "Transaction Number",
      dataIndex: "transactionNumber",
      key: "transactionNumber",
      width: "auto",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      width: "auto",
      render: RenderAmount,
    },
    {
      title: "Debit",
      dataIndex: "debitAccount",
      key: "debitAccount",
      sorter: true,
      width: "auto",
      render: (account: any) => account?.accountName,
    },
    {
      title: "Credit",
      dataIndex: "creditAccount",
      key: "creditAccount",
      sorter: true,
      width: "auto",
      render: (account: any) => account?.accountName,
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: "auto",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
    },
    {
      title: "Description",
      dataIndex: "transactionDescription",
      key: "transactionDescription",
      sorter: true,
      width: "auto",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: (_i: any, row: any) => (
        <TableActions
        />
      ),
      width: "auto",
    },
  ];

  const handleCreateTransactionModalClose = (refresh?: boolean) => {
    setCreateTransactionModalOpen(false);
  };

  const handleCreateTransactionModalOpen = () => {
    setCreateTransactionModalOpen(true);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setOffset((pagination.current || 1) - 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [limit, offset, sortQuery]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Typography.Title>Transactions</Typography.Title>
        </Col>
        <Col
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            icon={<PlusOutlined />}
            onClick={handleCreateTransactionModalOpen}
          >
            {" "}
            Create
          </Button>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          {/* <Table
            dataSource={data?.items}
            columns={columns}
            loading={isLoading}
            style={{ minHeight: "300px", height: "30%" }}
          /> */}
          <Table
            dataSource={data?.items || []}
            columns={columns}
            loading={isLoading || isRefetching}
            style={{ minHeight: "300px", height: "30%" }}
            scroll={{ x: "max-content" }}
            onChange={handleTableChange}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            // rowKey={(item) => item.idTransaction}
          />
        </Col>
      </Row>

      <CreateTransactionModal
        isOpen={createTransactionModalOpen}
        onClose={handleCreateTransactionModalClose}
      />
    </>
  );
}
