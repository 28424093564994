// Import necessary modules and components
import React, { useEffect, useState } from "react";
import { Col, Row, Table, Tag, Typography } from "antd";
import { motion } from "framer-motion";
import { useVendorsServiceGetVendorPurchases } from "api/queries";
import * as qs from "qs";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PurchaseOrderStatusTag from "pages/Purchases/PurchaseOrders/PurchaseOrderDetails/PurchaseOrderStatusTag";
import dayjs from "dayjs";

// Define the columns for the table

// Define the PurchaseOrders component
const PurchaseOrders = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");

  const company = useSelector((state: any) => state.company);
  const params = useParams();

  const columns: any[] = [
    {
      title: "Purchase Order Number",
      dataIndex: "purchaseOrderNumber",
      key: "purchaseOrderNumber",
      render: (text: any) => <Typography.Text>{text}</Typography.Text>,
      width: "auto",
    },
    {
      title: "Purchase Order Date",
      dataIndex: "purchaseOrderDate",
      key: "purchaseOrderDate",
      render: (text: any) => (
        <Typography.Text>{dayjs(text).format("DD/MM/YYYY")}</Typography.Text>
      ),
      width: "auto",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text: any) => <Typography.Text>{text}</Typography.Text>,
      width: "auto",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (amount: any, record: any) => (
        <Typography.Text>
          {record?.currency?.currencySymbol || company.currencySymbol}
          {amount.toLocaleString()}
        </Typography.Text>
      ),
      width: "auto",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => {
        return <PurchaseOrderStatusTag status={status} />;
      },
      width: "auto",
    },
  ];

  const { data, isLoading, isPending, isFetching, refetch } =
    useVendorsServiceGetVendorPurchases({
      filters: "",
      pagination: qs.stringify({ limit: 100, offset: 0 }),
      sort: sortQuery,
      vendorId: +(params?.id || "0"),
    });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [page, limit, sortQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      style={{ margin: "2rem 0" }}
    >
      <Row style={{ margin: "2rem 0", width: "100%" }}>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            pagination={{
              total: data?.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>
    </motion.div>
  );
};

// Export the component
export default PurchaseOrders;
