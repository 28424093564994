import { Col, Form, Input, Modal, Row, Select } from "antd";
import {
  useAccountingServiceGetBankAccountsKey,
  useAccountingServiceUpdateBankAccount,
} from "api/queries";
import CurrencySelect from "components/common/CurrencySelect";
import { useEffect } from "react";
import { BankAccountHolderType, BankAccountType } from "utils/enums";

interface IEditBankAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: any;
  defaultValues: any;
}

const EditBankAccountModal: React.FC<IEditBankAccountModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useAccountingServiceUpdateBankAccount({
    onError: (error: any) => {
      console.dir(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetBankAccountsKey],
        refetchType: "all",
      });
      form.resetFields();
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      id: defaultValues.idBankAccount,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
    });
  }, [form, defaultValues]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Edit Bank Account"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-bank-account-form"
      >
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item
              name="bankAccountName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountNumber"
              label="Account Number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountType"
              label="Type"
              rules={[{ required: true }]}
            >
              <Select
                options={Object.keys(BankAccountType).map((key) => ({
                  label: key,
                  value: key,
                  key: key + "bank-account-select-bank-account-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountHolderName"
              label="Holder Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="bankAccountHolderType"
              label="Holder Type"
              rules={[{ required: true }]}
            >
              <Select
                options={Object.keys(BankAccountHolderType).map((key) => ({
                  label: key,
                  value: key,
                  key: key + "bank-account-select-bank-account-holder-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="isActive"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { label: "Active", value: true },
                  { label: "Inactive", value: false },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditBankAccountModal;
