import { QueryClient } from "@tanstack/react-query";
import { Alert, Col, Form, Input, Modal, Row, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useCustomerServiceGetCustomersKey,
  useCustomerServiceUpdateCustomer,
} from "api/queries";
import CountrySelect from "components/common/CountrySelect";
import { useEffect } from "react";

export interface IEditCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
  defaultValues: any;
}

export const EditCustomerModal: React.FC<IEditCustomerModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending, isError, error } =
    useCustomerServiceUpdateCustomer({
      onError: (error: any) => {
        console.dir(error);
      },
      onSuccess: async (data) => {
        await queryClient.invalidateQueries({
          queryKey: [useCustomerServiceGetCustomersKey],
          refetchType: "all",
        });
        onClose();
      },
    });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idCustomer: defaultValues.idCustomer,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
    });
  }, [form, defaultValues]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Edit Customer"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-customer-form"
      >
        <Row gutter={16}>
          <Col xs={24}>
            {isError && (
              <Alert message={error?.message || error} type="error" showIcon />
            )}
          </Col>
        </Row>
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Customer Name"
              name="customerName"
              validateTrigger="onChange"
              rules={[
                { required: true, message: "Please enter a customer name" },
              ]}
            >
              <Input placeholder="Customer name" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Country"
              name="country"
              validateTrigger="onChange"
            >
              <CountrySelect withForm={true} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Address"
              name="address"
              validateTrigger="onChange"
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Phone"
              name="phone"
              validateTrigger="onChange"
            >
              <Input placeholder="Phone" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Email"
              name="email"
              validateTrigger="onChange"
              rules={[
                { type: "email", message: "The input is not valid E-mail" },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              hasFeedback
              label="Website"
              name="website"
              validateTrigger="onChange"
            >
              <Input placeholder="Website" />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              hasFeedback
              label="Description"
              name="description"
              validateTrigger="onChange"
            >
              <TextArea rows={3} placeholder="Description" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              hasFeedback
              label="Active"
              name="isActive"
              valuePropName="checked"
              validateTrigger="onChange"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
