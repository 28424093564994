import { motion } from "framer-motion";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Table,
  TableColumnType,
  TablePaginationConfig,
  Typography,
} from "antd";
import { useState } from "react";
import { useAccountingServiceGetCurrencies } from "api/queries";
import queryString from "query-string";
import { ICurrency } from "api/requests";
import { TableActions } from "components/common";
import CreateCurrencyModal from "./CreateCurrencyModal";
import { useQueryClient } from "@tanstack/react-query";
import EditCurrencyModal from "./EditCurrencyModal";

const CurrencyListingPage = () => {
  const [createCurrencyModalOpen, setCreateCurrencyModalOpen] = useState(false);
  const [updateCurrencyModalOpen, setUpdateCurrencyModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | null>(
    null
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [filtersQuery, setFiltersQuery] = useState("");

  const queryClient = useQueryClient();

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    if (pagination) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      const s: any = {};
      if (sorter.field === "exchangeRate") {
        s[sorter.field] = { rate: sorter.order === "ascend" ? "asc" : "desc" };
      } else {
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
      }
      setSortQuery(s);
    }
  };

  const { data, isLoading, isRefetching } = useAccountingServiceGetCurrencies({
    filters: filtersQuery,
    pagination: queryString.stringify({
      offset: (page - 1) * limit,
      limit,
    }),
    sort: sortQuery,
  });

  const handleOpenUpdateCurrencyModal = (currency: ICurrency) => {
    setSelectedCurrency(currency);
    setUpdateCurrencyModalOpen(true);
  };

  const handleCloseUpdateCurrencyModal = () => {
    setSelectedCurrency(null);
    setUpdateCurrencyModalOpen(false);
  };

  const columns: TableColumnType<ICurrency>[] = [
    {
      key: "currencyName",
      title: "Name",
      dataIndex: "currencyName",
      width: "auto",
      sorter: true,
    },
    {
      key: "currencyCode",
      title: "Code",
      dataIndex: "currencyCode",
      width: "auto",
      sorter: true,
    },
    {
      key: "currencySymbol",
      title: "Symbol",
      dataIndex: "currencySymbol",
      width: "auto",
      sorter: true,
    },
    {
      key: "exchangeRate",
      title: "Rate",
      dataIndex: "exchangeRate",
      width: "auto",
      render: (value) => `${value.rate}`,
      sorter: true,
    },
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      width: "auto",
      render: (_value, record) => (
        <TableActions onEdit={() => handleOpenUpdateCurrencyModal(record)} />
      ),
    },
  ];

  const handleOpenCreateCurrencyModal = () => {
    setCreateCurrencyModalOpen(true);
  };

  const handleCloseCreateCurrencyModal = () => {
    setCreateCurrencyModalOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24} md={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Currencies
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={24} md={12}>
          <Flex justify="end" align="center">
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={handleOpenCreateCurrencyModal}
            >
              Create
            </Button>
          </Flex>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            loading={isLoading || isRefetching}
            columns={columns}
            // @ts-ignore
            dataSource={data?.items}
            onChange={onTableChange}
            key="transaction-list"
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              responsive: true,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
            }}
            rowKey={(item) => item.idCurrency + "-currency-list"}
          />
        </Col>
      </Row>

      <CreateCurrencyModal
        isOpen={createCurrencyModalOpen}
        onClose={handleCloseCreateCurrencyModal}
        queryClient={queryClient}
      />

      <EditCurrencyModal
        isOpen={updateCurrencyModalOpen && !!selectedCurrency}
        onClose={handleCloseUpdateCurrencyModal}
        queryClient={queryClient}
        defaultValues={selectedCurrency}
      />
    </motion.div>
  );
};

export default CurrencyListingPage;
