import { QueryClient } from "@tanstack/react-query";
import { Empty, Form, Input, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useAccountingServiceCreateExpenseCategory,
  useAccountingServiceGetExpenseAccounts,
  useAccountingServiceGetExpenseCategoriesKey,
} from "api/queries";
import qs from "qs";
import { useEffect, useState } from "react";

interface ICreateExpenseCategoryModalProps {
  isOpen: boolean;
  onClose: (isSuccess?: boolean) => void;
  queryClient: QueryClient;
}

export const CreateExpenseCategoryModal: React.FC<
  ICreateExpenseCategoryModalProps
> = ({ isOpen, onClose, queryClient }) => {
  const [accountSearchQuery, setAccountSearchQuery] = useState("");

  const [form] = Form.useForm();

  const { mutate, isPending } = useAccountingServiceCreateExpenseCategory({
    onError: (error: any) => {
      console.dir(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useAccountingServiceGetExpenseCategoriesKey],
      });
      form.resetFields();
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({ requestBody: values });
  };

  const { data, isLoading, isRefetching, refetch } =
    useAccountingServiceGetExpenseAccounts({
      filters: qs.stringify({
        accountName: { $ilike: `${accountSearchQuery}%` },
      }),
      pagination: "",
      sort: "",
    });

  const handleSearchAccounts = (value: string) => {
    setAccountSearchQuery(value);
  };

  useEffect(() => {
    refetch();
  }, [accountSearchQuery]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => onClose()}
      title="Create Expense Category"
      onOk={form.submit}
      okText="Create"
      cancelText="Cancel"
      destroyOnClose
      afterClose={() => form.resetFields()}
      confirmLoading={isPending}
      cancelButtonProps={{ disabled: isPending }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="create-expense-category-form"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please input the category name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              required: true,
              message: "Please input the category description!",
            },
          ]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          name="account"
          label="Account"
          rules={[{ required: true, message: "Please select an account!" }]}
        >
          <Select
            loading={isLoading || isRefetching }
            options={data?.items?.map((item) => ({
              value: item.idAccount,
              label: item.accountName,
              key: item.idAccount + "account-select-account",
            }))}
            placeholder="Select an account"
            allowClear={true}
            onSearch={handleSearchAccounts}
            searchValue={accountSearchQuery}
            showSearch
            notFoundContent={
              isLoading || (data && data.total > 0) ? false : <Empty />
            }
            filterOption={false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
