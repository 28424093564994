import { useInventoryServiceGetInventoryTransactions } from "api/queries";
import { motion } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";
import queryString from "qs";
import {
  Button,
  Col,
  Divider,
  Flex,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { ColumnsType, ColumnType } from "antd/es/table";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TableActions } from "components/common";
import CreateTransactionModal from "./CreateTransactionModal";

export default function InventoryTransactionListing() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [filtersQuery, setFiltersQuery] = useState("");
  const [createTransactionModalOpen, setCreateTransactionModalOpen] =
    useState(false);

  const queryClient = useQueryClient();
  const nav = useNavigate();

  const { data, isLoading, isPending, isFetching, refetch } =
    useInventoryServiceGetInventoryTransactions({
      filters: "",
      pagination: queryString.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      sort: sortQuery,
    });

  const columns: ColumnType<any>[] = [
    {
      title: "Type",
      dataIndex: "transactionType",
      key: "transactionType",
      width: "auto",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "auto",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (product: any) => product?.productName,
      width: "auto",
    },
    {
      title: "From Location",
      dataIndex: "locationFrom",
      key: "locationFrom",
      render: (location: any) => location?.locationName,
      width: "auto",
    },
    {
      title: "To Location",
      dataIndex: "locationTo",
      key: "locationTo",
      render: (location: any) => location?.locationName,
      width: "auto",
    },
    {
      title: "Description",
      dataIndex: "inventoryTransactionDescription",
      key: "inventoryTransactionDescription",
      width: "auto",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_i: any, row: any) => (
        <TableActions
        // onView={() => nav(`/inventory/transactions/${row.id}`)}
        // onEdit={() => console.log("edit")}
        />
      ),
      width: "auto",
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  const handleCreateTransactionModalClose = () => {
    setCreateTransactionModalOpen(false);
  };

  const handleCreateTransactionModalOpen = () => {
    setCreateTransactionModalOpen(true);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Transactions
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={12}>
          <Flex justify="end" align="center">
            <Button icon={<PlusOutlined />} type="primary" onClick={handleCreateTransactionModalOpen}>
              Create
            </Button>
          </Flex>
        </Col>
        <Col xs={24}>
          <Divider style={{ opacity: 0.4 }} />
        </Col>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={data?.items}
            loading={isLoading || isPending || isFetching}
            key={"transaction-list"}
            rowKey={(item) => item.idTransaction}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              onChange: (page) => setPage(page),
              onShowSizeChange: (_, limit) => setLimit(limit),
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
      <CreateTransactionModal
        isOpen={createTransactionModalOpen}
        onClose={handleCreateTransactionModalClose}
        queryClient={queryClient}
      />
    </motion.div>
  );
}
