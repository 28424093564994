import {
  useVendorsServiceGetVendorById,
  useVendorsServiceGetVendorByIdKey,
} from "api/queries";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  Col,
  Divider,
  Flex,
  Row,
  Segmented,
  Skeleton,
  Spin,
  Typography,
} from "antd";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { VendorDetailsComponent } from "./VendorDetails";
import PurchaseOrders from "./VendorPurchaseOrders";
import StatementOfAccount from "./StatementOfAccount";

export default function VendorDetailsPage() {
  const [currentOption, setCurrentOption] = useState<
    "details" | "purchases" | "statement-of-account"
  >("details");

  const params = useParams();

  const { data, isLoading } = useVendorsServiceGetVendorById(
    {
      vendorId: +(params?.id || "0"),
    },
    [useVendorsServiceGetVendorByIdKey]
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        {isLoading ? (
          <Col xs={24}>
            <Flex justify="center" align="center" style={{ minHeight: "40vh" }}>
              <Spin />
            </Flex>
          </Col>
        ) : data ? (
          <>
            <Col xs={12}>
              <Skeleton loading={isLoading} active>
                <Typography.Title level={2} style={{ margin: 0 }}>
                  {data?.vendorName}
                </Typography.Title>
              </Skeleton>
            </Col>

            <Col xs={24}>
              <Divider style={{ opacity: 0.5 }} />
            </Col>

            <Col xs={24}>
              <Segmented
                options={[
                  { label: "Details", value: "details" },
                  { label: "Purchases", value: "purchases" },
                  {
                    label: "Statement of Account",
                    value: "statement-of-account",
                  },
                ]}
                value={currentOption}
                onChange={(value: any) => setCurrentOption(value)}
              />
            </Col>

            <Col xs={24}>
              <AnimatePresence>
                {currentOption === "details" && (
                  <VendorDetailsComponent data={data} />
                )}

                {currentOption === "purchases" && <PurchaseOrders />}

                {currentOption === "statement-of-account" && (
                  <StatementOfAccount vendorId={data?.idVendor} vendorDetails={data} />
                )}
              </AnimatePresence>
            </Col>
          </>
        ) : (
          <Col xs={24}>
            <Skeleton loading={isLoading} active>
              <Typography.Title level={2} style={{ margin: 0 }}>
                Vendor not found
              </Typography.Title>
            </Skeleton>
          </Col>
        )}
      </Row>
    </motion.div>
  );
}
