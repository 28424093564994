import { QueryClient } from "@tanstack/react-query";
import { Col, Form, Input, Modal, Row, Select, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useInventoryServiceGetLocationsKey,
  useInventoryServiceUpdateLocation,
} from "api/queries";
import { useEffect } from "react";
import { LocationType } from "utils/enums";

export interface IEditLocationModalProps {
  isOpen: boolean;
  onClose: () => void;
  queryClient: QueryClient;
  defaultValues: any;
}

export const EditLocationModal: React.FC<IEditLocationModalProps> = ({
  isOpen,
  onClose,
  queryClient,
  defaultValues,
}) => {
  const [form] = Form.useForm();

  const { mutate, isPending } = useInventoryServiceUpdateLocation({
    onError: (error: any) => {
      console.dir(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [useInventoryServiceGetLocationsKey],
        refetchType: "all",
      });
      onClose();
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      requestBody: values,
      idLocation: defaultValues.idLocation,
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...defaultValues,
    });
  }, [form, defaultValues]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title="Edit Location"
      onOk={() => form.submit()}
      okButtonProps={{ loading: isPending }}
      cancelButtonProps={{ disabled: isPending }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        name="edit-location-form"
      >
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item
              name="locationName"
              label="Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              name="locationType"
              label="Type"
              rules={[{ required: true }]}
            >
              <Select
                options={Object.keys(LocationType).map((key) => ({
                  value: key,
                  label: key,
                  key: key + "location-select-location-type",
                }))}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="locationDescription"
              label="Description"
              rules={[{ required: true }]}
            >
              <TextArea />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="isActive"
              label="Is Active"
              rules={[{ required: true }]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
